import Cookies from 'js-cookie';

import { UnlockTransactionAPI } from 'helper/api/route';
import {
  clearLocalStoragePartly,
  getStorageData,
  removeStorageDataByKey,
} from 'util/storageHelper';
import { LOCAL_STORAGE_LNG, DATASET_STORAGE_KEY } from 'constants/Static';
import { ERoutePaths } from 'enums';

export const logOut = async () => {
  const state: string = getStorageData('state') || '';
  const stateData = state ? JSON.parse(state) : {};
  const { transactionCode = '' } = stateData;

  if (transactionCode) await UnlockTransactionAPI(transactionCode);
  removeStorageDataByKey('state');
  clearLocalStoragePartly([LOCAL_STORAGE_LNG, DATASET_STORAGE_KEY]);
  const cookiesList: string[] = Object.keys(Cookies.get()) || [];
  cookiesList.forEach((cookie: string) => {
    if (cookie.startsWith('access_token')) Cookies.remove(cookie);
  });
  window.location.replace(ERoutePaths.root);
};

export const isAuthorized = () => Cookies.get('access_token');
