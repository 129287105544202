import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { batchActions } from 'redux-batched-actions';

import { ReactComponent as CloseLogo } from 'images/close.svg';
import Table from 'components/table/Table';
import columns from 'constants/TableColumns';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { getSelectedDataset, translateColumnsHeaders } from 'helper';
import { TDataset } from 'store/features/agentManagement/types';
import * as agentActions from 'store/features/agentManagement/actions';

type TExtendedDataset = {
  switchTo: React.JSX.Element;
  linkedAccounts: string;
  datasetName: string;
};

const SwitchDataset = (props: { close: Function }) => {
  const { t } = useTranslation();
  const { close } = props;
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const modalFocus = useRef<HTMLDivElement>(null);
  const { agent } = state.agentProfile || {};
  const { datasets = [] } = agent || {};
  const datasetId = useMemo(() => getSelectedDataset(datasets)?.id, [datasets]);

  useEffect(() => {
    if (modalFocus.current) {
      modalFocus.current.focus();
    }
  }, []);

  const clickHandler = (selectedDataset: TDataset) => {
    if (agent) {
      const updatedDatasets = datasets.reduce((acc: TDataset[], dataset: TDataset) => {
        acc.push({ ...dataset, selected: selectedDataset.id === dataset.id });
        return acc;
      }, []) || [];
      dispatch(batchActions([
        agentActions.setDefaultDataset(selectedDataset.id),
        agentActions.setAgent({ ...agent, datasets: updatedDatasets }),
      ]));
      close(false);
    }
  };

  const data = datasets?.reduce((acc: TExtendedDataset[], dataset: TDataset) => {
    if (dataset.id !== datasetId) {
      acc.push({
        datasetName: dataset.name,
        linkedAccounts: dataset?.accounts?.map((account) => account.name).join(', ') || '',
        switchTo: <SingleButton {...{
          textButton: 'dashboard.switch-dataset.switch.btn',
          onClick: () => clickHandler(dataset),
          radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
          variant: BUTTON_STYLE.SECONDARY,
        }} />,
      });
    }
    return acc;
  }, []);

  return (
    <div className='modal-wrapper'>
      <div className='modal-header'>
        <div
          className="dashboard-modal-title switch-dataset-modal-title"
          ref={modalFocus} tabIndex={0}
        >
          <p>{t('dataset-switch.header')}</p>
        </div>
        <div
          className=" button close-modal-button"
          role="button"
          onClick={() => close(false)}
          tabIndex={0}
        >
          <CloseLogo />
        </div>
      </div>
      <div className="modal-body switch-dataset-modal">
        <Table data={data} columns={translateColumnsHeaders(columns.switchDataset, t)} />
      </div>
    </div>
  );
};

export default SwitchDataset;