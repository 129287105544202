import { TAgentProfile } from 'store/features/agentManagement/types';
import { getLocalStorageData } from 'util/storageHelper';

export default (): TAgentProfile => {
  const agentJson: string | null = getLocalStorageData('agent');
  const datasetId: string | null = getLocalStorageData('dataset_id');
  return {
    agent: agentJson ? JSON.parse(agentJson) : null,
    datasetId: datasetId ? +datasetId : null,
  };
};
