import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFileNames } from 'helper';
import { EFileTypes, EStatuses, EWorkflowModules } from 'enums';
import { IRootState } from 'store/types';
import { ldStatusMessages } from 'constants/Static';
import { TManualReview } from 'store/features/reviewResult/types';
import AccordionField from 'components/accordionField/AccordionField';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';

const SelfieVideo = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData } = state.reviewResult;
  const pageEnabled = transactionData?.modules.includes(EWorkflowModules.livenessDetection);

  const { reviewId = '' } = useParams();
  const { manualReview = [] } = transactionData;
  const currentReview = useMemo(() => manualReview.find(
    (review: TManualReview) => review?.history?.reviewId === reviewId
  ), [manualReview, reviewId]);
  const { identityReview } = currentReview || {};
  const { livenessDetectionReview } = identityReview || {};
  const result = EStatuses[livenessDetectionReview?.result as keyof typeof EStatuses] || EStatuses.notPerformed;
  const [selfieVideo = ''] = useMemo(() => getFileNames(transactionData, [EFileTypes.selfieVideo]), [transactionCode]);

  const selfieVideoPlayer = useMemo(() => <VideoPlayer
    filename={selfieVideo}
    filetype={EFileTypes.selfieVideo}
    transactionCode={transactionCode}
  />, []);

  const content = useMemo(() => {
    return (
      <div className="selfie-video-container-summary">
        <p className="video-status">
          {t('identity.ld.result.title')}
          <span className={result}>{pageEnabled && t(ldStatusMessages[result])}</span>
        </p>
        <div className="video-section">
          {selfieVideoPlayer}
        </div>
      </div>
    );
  }, [transactionCode, transactionData]);

  return (<>
    <div className='selfie-video-content'>
      <AccordionField
        title={t('identity.ld.heading')}
        body={content}
        className={'accordion-container'}
        showStatus={pageEnabled}
        eventKey={1}
        preventToggle={!pageEnabled}
      />
    </div>
  </>);
};

export default SelfieVideo;