export enum AgentActions {
  setAgent = 'SET_AGENT',
  setDefaultDataset = 'SET_DEFAULT_DATASET',
}

type TDatasetAccount = {
  id: number;
  name: string;
}

export type TDataset = {
  id: number;
  name: string;
  accounts: TDatasetAccount[];
  selected?: boolean;
}

export type TAgent = {
  id: number;
  email: string;
  name: string;
  datasets: TDataset[];
}

export type TAgentProfile = {
  agent: TAgent;
  datasetId: number | null;
};

export type TAgentActions =
  { type: AgentActions.setAgent; value: TAgent } |
  { type: AgentActions.setDefaultDataset; value: number };
