import produce from 'immer';

import { setLocalStorageDataItem } from 'util/storageHelper';
import { AgentActions, TAgentProfile, TAgentActions } from 'store/features/agentManagement/types';
import getInitialState from 'store/features/agentManagement/initialState';

export const agentProfileReducer = (state: TAgentProfile = getInitialState(), action: TAgentActions) => {
  const nextState = produce(state, (draftState: TAgentProfile) => {
    const { type, value } = action;
    switch (type) {
    case AgentActions.setAgent:
      draftState.agent = value;
      setLocalStorageDataItem('agent', value);
      break;
    case AgentActions.setDefaultDataset:
      draftState.datasetId = value;
      setLocalStorageDataItem('dataset_id', value);
      break;
    }
    return draftState;
  });
  return nextState;
};
