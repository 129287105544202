import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EComponentTypes, EFields } from 'enums';
import { fieldValueValidated } from 'helper/validation';
import { TFieldData, TFiledItemResult } from 'store/features/reviewResult/types';
import ToolTip from 'components/toolTipField/ToolTipField';
import { areValuesEqual } from 'helper';
import { CompareFieldsAPI } from 'helper/api/route';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { COMPARE_LIST, DEFAULT_DELAY } from 'constants/Static';
import DatePicker from 'components/datePicker/DatePicker';
import SelectOption from 'components/select/SelectOption';
import { IOptionType } from 'types';

interface IInputProps {
  transactionCode: string;
  field: EFields;
  componentType: EComponentTypes;
  translationKey: string;
  leftData: TFiledItemResult;
  setPersonalInfo: (newValues: TFieldData) => void;
  selectOptions?: IOptionType[];
  rightData?: TFiledItemResult;
  getPlaceholder?: Function;
}

const getCustomStyles = (options: { edited: boolean, mismatch: boolean, disabled: boolean }) => {
  return {
    customStyles: {
      control: (base: any) => ({
        ...base,
        minHeight: '100%',
        borderRadius: '0',
        boxShadow: 'none',
        border: '1px solid #d0cccc',
        backgroundColor: options.disabled ? '#e7e7e7' : options.edited ? '#ffcc40' : '#fff',
      }),
      placeholder: (base: any) => ({
        ...base,
        fontSize: '0.875rem',
        color: options.mismatch ? 'red' : '#064',
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        color: options.mismatch ? 'red' : '#064',
        '&:hover': {
          color: '#d0cccc',
        },
        pointerEvents: 'none',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        pointerEvents: 'none',
        display: 'none',
        backgroundColor: 'unset',
      }),
    },
  };
};

const FieldComparison = (props: IInputProps) => {
  const { t, i18n } = useTranslation();
  const {
    transactionCode, field, translationKey, leftData, rightData,
    setPersonalInfo, componentType, selectOptions, getPlaceholder,
  } = props;
  const [mismatch, setMismatch] = useState(false);
  const [leftValue, setLeftValue] = useState(leftData.value);
  const [rightValue, setRightValue] = useState(rightData?.value || '');
  const debouncedLeftValue = useDebouncedValue(leftValue, DEFAULT_DELAY);
  const debouncedRightValue = useDebouncedValue(rightValue, DEFAULT_DELAY);
  const prevLeftValue = useRef(debouncedLeftValue);
  const prevRightValue = useRef(debouncedRightValue);
  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    setIsReady(false);
    const timeout = setTimeout(() => {
      if (leftData.value !== leftValue) {
        setLeftValue(leftData.value);
      }
      if (rightData?.value !== rightValue) {
        setRightValue(rightData?.value || '');
      }
      setIsReady(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, [field]);

  useEffect(() => {
    if (debouncedLeftValue === leftValue && debouncedRightValue === rightValue) {
      if (rightValue && leftValue) {
        if (COMPARE_LIST.includes(field)) {
          CompareFieldsAPI(transactionCode, {
            [field]: {
              asciiString: rightValue,
              unicodeString: leftValue,
            },
          }).then((compareResult) => {
            setMismatch(!compareResult.data[field].equals);
          }).catch(() => {
            setMismatch(!areValuesEqual(leftValue, rightValue));
          });
        } else {
          setMismatch(!areValuesEqual(leftValue, rightValue));
        }
      }
      prevLeftValue.current = leftValue;
      prevRightValue.current = rightValue;
      const personalInfo: TFieldData = {};
      const leftValueIsValid = fieldValueValidated(field, leftData.zone, leftValue, leftData.optional);
      if (leftValueIsValid && leftValue !== leftData.value) {
        personalInfo[leftData.zone] = { ...leftData, value: leftValue };
      }
      if (rightData) {
        const rightValueIsValid = fieldValueValidated(field, rightData.zone, rightValue, rightData.optional);
        if (rightValueIsValid && rightValue !== rightData.value) {
          personalInfo[rightData.zone] = { ...rightData, value: rightValue };
        }
      }

      if (Object.keys(personalInfo).length) {
        setPersonalInfo(personalInfo);
      }
    }
  }, [debouncedLeftValue, debouncedRightValue, leftValue, rightValue]);

  const allowToCopyValue = useCallback((value: string, options: { left: boolean }) => {
    if (!rightData || !leftData) return false;
    const targetData = options.left ? leftData : rightData;
    const valueIsValid = fieldValueValidated(field, targetData.zone, value, targetData.optional);
    return valueIsValid && leftData.editable && rightData.editable && !targetData?.disabled
      && !areValuesEqual(leftValue, rightValue);
  }, [rightValue, leftValue]);

  const getClassName = useCallback((data: TFiledItemResult | undefined, value: string) => {
    let className = 'custom-input';
    if (!data) return className;
    if (data.editable && (data.extractedValue !== value)) {
      className += ' edited';
    }
    if (data.editable && mismatch) {
      className += ' mismatch';
    }
    if (!data.editable) {
      className += ' not-editable';
    }
    return className;
  }, [mismatch]);

  const getFieldComponent = useCallback((options: { left: boolean }) => {
    const data = options.left ? leftData : rightData;
    const value = options.left ? leftValue : rightValue;
    const onChange = options.left ? setLeftValue : setRightValue;
    if (!data) return null;
    const { zone, editable, disabled, optional, extractedValue } = data;
    if (!editable) {
      return <input
        type='text'
        value={t('identity.doc.data.not-applicable')}
        readOnly={true}
        disabled={disabled}
        className={getClassName(data, value)}
        onChange={(e) => onChange(e.target.value)}
      />;
    }
    switch (componentType) {
    case EComponentTypes.input:
      return <input
        type='text'
        value={value}
        readOnly={!editable}
        disabled={disabled}
        className={getClassName(data, value)}
        onChange={(e) => onChange(e.target.value)}
      />;
    case EComponentTypes.datepicker:
      return <DatePicker
        fieldValue={value}
        setFieldValue={onChange}
        optional={data.optional}
        validated={fieldValueValidated(field, zone, value, optional)}
        disabled={data.disabled}
        className={getClassName(data, value)}
      />;
    case EComponentTypes.select:
      return <SelectOption
        handler={(selected: IOptionType) => onChange(selected.value)}
        placeholder={getPlaceholder ? getPlaceholder(value, selectOptions) : value}
        options={selectOptions ?? []}
        disabled={data.disabled}
        value={value}
        customStylesSelect={getCustomStyles({ edited: extractedValue !== value, mismatch, disabled })}
      />;
    default:
      return null;
    }
  }, [field, leftValue, rightValue, mismatch, rightData, leftData, i18n.language]);

  if (!isReady) return null;

  return (
    <div className='custom-comparison-input-container'>
      <ToolTip placement='top' tooltipValue={t(translationKey)} >
        <span className='custom-input-field-key'>{t(translationKey)}: </span>
      </ToolTip>
      <div className='custom-input-container'>
        {getFieldComponent({ left: true })}
        {rightData ? <button
          onClick={() => setLeftValue(rightValue)}
          className={allowToCopyValue(rightValue, { left: true }) ? 'copy-allowed' : ''}
          disabled={!allowToCopyValue(rightValue, { left: true })}
        >
          &#x276E;
        </button> : null}
      </div>
      {rightData ? <div className='custom-input-container'>
        <button
          onClick={() => setRightValue(leftValue)}
          className={allowToCopyValue(leftValue, { left: false }) ? 'copy-allowed' : ''}
          disabled={!allowToCopyValue(leftValue, { left: false })}
        >
          &#x276F;
        </button>
        {getFieldComponent({ left: false })}
      </div> : null}
    </div>
  );
};

export default FieldComparison;
