import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logOut } from 'util/authService';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EHttpStatusCode } from 'enums';

const toastList = new Set();
const MAX_COUNT = 1;
const safeMethods = ['post', 'delete', 'update', 'put'];

const toastOptions = {
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  transition: Slide,
};

const notify = (message: string) => {
  if (toastList.size < MAX_COUNT) {
    const countToast = toast.error(
      message,
      {
        ...toastOptions,
        onClose: () => toastList.delete(countToast),
      },
    );
    toastList.add(countToast);
  }
};

const axiosBase = Axios.create();
axiosBase.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axiosBase.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    try {
      if (config.method) {
        const metaTagElement = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement;
        const csrfHeader = safeMethods.includes(config.method)
          ? { 'x-csrf-token': metaTagElement.content }
          : {};
        const headers = {
          ...(config.headers && { ...config.headers }),
          ...csrfHeader
        };
        config.headers = headers;
      }
      return config;
    } catch (e) {
      return config;
    }
  },
);

axiosBase.interceptors.response.use(
  (response) => response,
  (err: AxiosError) => {
    if (err.response) {
      if (err.response.status === EHttpStatusCode.forbidden) {
        notify('User not found');
      } else if (err.response.status === EHttpStatusCode.internalServerError) {
        notify(err.response.statusText);
      }
    }

    if (err.response && (err.response.status === EHttpStatusCode.unauthorized)
      && !err.request.responseURL.endsWith('/sign-in')) {
      logOut();
    } else {
      throw err;
    }
  },
);

export default axiosBase;
