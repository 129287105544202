import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ToolTip from 'components/toolTipField/ToolTipField';
import { EAnswers, EReviewStates, ERoutePaths } from 'enums';
import { IRootState } from 'store/types';
import { CompleteTransactionAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import { getTransactionReviewResult } from 'helper';
import { acceptanceCheck, generateCheckResult, rejectionCheck } from 'pages/review/helper';

const Footer = (props: {
  setShow: (value: boolean) => void;
  handleAnswerChange: (value: string[]) => void;
  setResponseError: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const state = useSelector((rootState: IRootState) => rootState);
  const { email = '' } = state.agentProfile?.agent || {};
  const { transactionCode, transactionData, manualCheckResult } = state.reviewResult;
  const { modules = [] } = transactionData || {};
  const checkResult = generateCheckResult(manualCheckResult);
  const [postProcessing, setPostProcessing] = useState(false);
  const { setShow, handleAnswerChange, setResponseError } = props;

  const complete = (reviewResult: EReviewStates) => {
    const errorMessages = [];
    if(reviewResult === EReviewStates.ACCEPTED) {
      errorMessages.push(...acceptanceCheck(t, modules, manualCheckResult));
    }
    if(reviewResult === EReviewStates.REJECTED) {
      errorMessages.push(...rejectionCheck(t, modules, manualCheckResult));
    }
    if (errorMessages.length) {
      setShow(true);
      handleAnswerChange(errorMessages)
      return;
    }
    const reviewedData = getTransactionReviewResult(manualCheckResult, transactionData?.modules || [], {
      email,
      reviewResult,
      postProcessing,
      message,
    });
    setLoader(true);
    CompleteTransactionAPI(transactionCode, reviewedData).then(() => {
      setLoader(false);
      navigate(ERoutePaths.dashboard);
    }).catch(() => {
      setLoader(false);
      setShow(true);
      setResponseError(true);
      handleAnswerChange([t('footer.modal.fail.message')]);
    });
  };

  if (loader) {
    return <div className="footer-container"><div className="loader-container"> <Loader /></div></div>;
  }

  return (
    <div className="footer-container">
      <div className="manual-check">
        <h6>{t('footer.result.title')}</h6>
        {checkResult.map(({ result, resultKey, title }, index) => (
          <div className="check-item" key={index}>
            <span className={`check-mark ${result === EAnswers.yes ? 'tick' : 'cross'}`}>
              {result === EAnswers.yes ? '✔' : '✘'}
            </span>
            <span>{t(title)}</span>
            <span className={'result'}>
              {t(resultKey)}
            </span>
          </div>
        ))}
        <div className="post-processing">
          <div className="line"></div>
          <div>
            <ToolTip placement='top' tooltipValue={t('footer.checkbox.post-processing.tooltip')} >
              <input
                type="checkbox"
                checked={postProcessing}
                onChange={(event) => setPostProcessing(event.target.checked)}
              />
            </ToolTip>
            <label htmlFor="postProcessing">{t('reject.post-processing.title')}</label>
          </div>
        </div>
      </div>
      <div className="summary">
        <h6>{t('footer.comment.input-title')}</h6>
        <textarea
          placeholder={t('footer.comment.placeholder')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="action-buttons">
          <button onClick={() => complete(EReviewStates.ACCEPTED)}>{t('footer.btn.accept')}</button>
          <button onClick={() => complete(EReviewStates.REJECTED)}>{t('footer.btn.reject')}</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
