
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Next } from 'images/next.svg';
import { formatDate } from 'util/common';
import { DATE_FORMAT, DISPLAY_DATE_FORMAT, KEY_CODE, MAXIMUM_YEAR, MINIMUM_YEAR } from 'constants/Static';
import { EDirection, EMonths } from 'enums';
import { ICustomHeaderProps, IDatePickerProps, ILocalDate, TChangeYearFunction } from 'types';
import { getDateItems, getSelectedDate } from 'helper';

const DatePickerComponent = (props: IDatePickerProps) => {
  const { fieldValue, optional, setFieldValue, validated, disabled, className } = props;
  const { t } = useTranslation();
  const [{ day, month, year }, setValue] = useState<ILocalDate>(getDateItems(new Date(fieldValue)));

  useEffect(() => setValue(getDateItems(new Date(fieldValue))), [fieldValue]);

  const handleCalendarClose = () => {
    const date = getSelectedDate({ day, month, year });
    if (date) {
      setFieldValue(formatDate(date, DATE_FORMAT));
    } else if (optional) {
      setFieldValue('');
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date || optional) {
      setValue(getDateItems(date));
    }
  };

  const onYearInputChange = (ev: React.ChangeEvent<HTMLInputElement>, changeYear: TChangeYearFunction) => {
    const changedYear = Number(ev.target.value);
    if (changedYear >= MINIMUM_YEAR && changedYear <= MAXIMUM_YEAR) {
      changeYear(changedYear);
    }
    setValue(prev => ({ ...prev, year: changedYear }));
  };

  const onChangeYear = (changeYear: TChangeYearFunction, direction: EDirection) => {
    const changedYear = year + direction;
    if (changedYear >= MINIMUM_YEAR && changedYear <= MAXIMUM_YEAR) {
      changeYear(changedYear);
      setValue(prev => ({ ...prev, year: changedYear }));
    }
  };

  const onDecreaseMonth = (decreaseMonth: Function, changeYear: TChangeYearFunction) => {
    decreaseMonth();
    if (month === EMonths.jan) {
      changeYear(year - 1);
      setValue(prev => ({ ...prev, month: EMonths.dec, year: year - 1 }));
    } else {
      setValue(prev => ({ ...prev, month: month - 1 }));
    }
  };

  const onIncreaseMonth = (increaseMonth: Function, changeYear: TChangeYearFunction) => {
    increaseMonth();
    if (month === EMonths.dec) {
      changeYear(year + 1);
      setValue(prev => ({ ...prev, month: EMonths.jan, year: year + 1 }));
    } else {
      setValue(prev => ({ ...prev, month: month + 1 }));
    }
  };

  const renderCustomCalendarHeader = ({ changeYear, decreaseMonth, increaseMonth }: ICustomHeaderProps) => (
    <div className="custom-calendar-container">
      <div className="custom-calendar-element">
        <Back onClick={() => onChangeYear(changeYear, EDirection.left)} />
        <div className="custom-calendar-year">
          <input
            value={year || ''}
            type="text"
            pattern="\d*"
            inputMode="numeric"
            onChange={(ev) => onYearInputChange(ev, changeYear)}
          />
        </div>
        <Next onClick={() => onChangeYear(changeYear, EDirection.right)} />
      </div>
      <div className="custom-calendar-element">
        <Back onClick={() => onDecreaseMonth(decreaseMonth, changeYear)} />
        <span>{t(`calendar-months.${EMonths[month || new Date().getMonth() + 1]}`)}</span>
        <Next onClick={() => onIncreaseMonth(increaseMonth, changeYear)} />
      </div>
    </div>
  );

  const keyPress: Function = (e: KeyboardEvent) => {
    if (fieldValue && e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
    }
  };

  const handleFocus = () => {
    if (!fieldValue) {
      const now = new Date();
      setValue({ day: now.getDate(), month: now.getMonth() + 1, year: now.getFullYear() });
    }
  };

  const maskedInput = (
    <MaskedInput
      type="text"
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
      onKeyUp={(e) => keyPress(e)}
      className={`date-picker-item ${validated ? 'default-border' : 'error-border'}`}
    />
  );

  return (
    <div className="date-picker-main">
      <DatePicker
        popperPlacement="top"
        selected={getSelectedDate({ day, month, year })}
        disabled={disabled}
        customInput={maskedInput}
        onChange={(date) => handleDateChange(date)}
        onCalendarClose={handleCalendarClose}
        placeholderText="__.__.____"
        dateFormat={DISPLAY_DATE_FORMAT}
        onFocus={handleFocus}
        renderCustomHeader={(props: ICustomHeaderProps) => renderCustomCalendarHeader(props)}
        className={className}
      />
    </div>
  );
};

export default DatePickerComponent;
