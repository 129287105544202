import React, { useRef, useState, useEffect, useCallback, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { LOADING_DURATION } from 'constants/Static';
import { getNotFoundImage, getNotFoundSpecimen } from 'helper';
import { GetResourceAPI } from 'helper/api/route';

interface IComparisonProps {
  transactionCode: string;
  filename: string;
  specimenImage: string;
  externalSource: boolean;
  setFilename: (filename: string) => void;
}

const Comparison = (props: IComparisonProps) => {
  const { specimenImage, transactionCode, filename, setFilename, externalSource } = props;
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [resourceData, setResourceData] = useState({
    documentImageLoaded: false,
    documentImageUnavailable: false,
    specimenLoaded: false,
    specimenImageNotFound: false,
    specimenImageUnavailable: false,
  });
  const [documentImage, setDocumentImage] = useState('');
  const divisorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverImageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverContainerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const firstImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const secondImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;

  useEffect(() => {
    divisorRef.current.style.width = `${value}%`;
  });

  useEffect(() => {
    setValue(0);
    if (!specimenImage) {
      setResourceData(prevState => ({ ...prevState, specimenImageNotFound: true }));
      return;
    }
    setResourceData(prevState => ({ ...prevState, specimenImageNotFound: false }));
    const specimenImageData = new Image();
    specimenImageData.src = specimenImage;
    specimenImageData.onload = () => {
      setResourceData(prevState => ({ ...prevState, specimenLoaded: true }));
    };
    specimenImageData.onerror = () => {
      setResourceData(prevState => ({ ...prevState, specimenImageUnavailable: true }));
    };
    const intervalId: NodeJS.Timeout = setInterval(() => {
      if (!resourceData.specimenLoaded) {
        setResourceData(prevState => ({ ...prevState, specimenLoaded: true }));
      }
    }, LOADING_DURATION);
    return () => clearTimeout(intervalId);
  }, [specimenImage]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setValue(0);
    if (!filename) {
      setResourceData(prevState => ({
        ...prevState,
        documentImageLoaded: true,
        documentImageUnavailable: true,
      }));
    } else {
      GetResourceAPI(transactionCode, { filename }, source.token).then((response) => {
        setDocumentImage(response.data.content);
        setResourceData(prevState => ({ ...prevState, documentImageLoaded: true}));
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          setResourceData(prevState => ({
            ...prevState,
            documentImageLoaded: true,
            documentImageUnavailable: true,
          }));
        }
      });
    }
  }, []);

  const getWidth = useCallback(() => (coverContainerRef.current.offsetWidth), [coverContainerRef]);

  const imageSrc = useMemo(() => {
    if (resourceData.documentImageUnavailable) {
      return getNotFoundImage(i18n.language);
    }
    return documentImage ? getImageFromBase64(documentImage) : '';
  }, [documentImage, resourceData.documentImageUnavailable, i18n.language]);

  const getSpecimenImage = () => {
    if (resourceData.specimenImageNotFound) {
      return getNotFoundSpecimen(i18n.language);
    }
    if (resourceData.specimenImageUnavailable) {
      return getNotFoundImage(i18n.language);
    }
    return specimenImage;
  };

  return (
    <>
      <div className="document-comparison-section" ref={coverContainerRef}>
        {(!resourceData.specimenLoaded || !resourceData.documentImageLoaded) && (
          <div className="loader-container"> <Loader /></div>
        )}
        <div className='comparison'>
          <div
            className="first-image-container"
          >
            <button
              className='transform-image-button'
              onClick={() => {
                if (setFilename && documentImage) setFilename((filename) || '');
              }}>
              {
                imageSrc && <img
                  className="image-style"
                  src={imageSrc}
                  alt="document"
                  ref={secondImageRef}
                  onLoad={() => {
                    coverImageRef.current.style.width = `${getWidth()}px`;
                    setResourceData(prevState => ({
                      ...prevState,
                      documentImageLoaded: true,
                    }));
                  }}
                  draggable
                />
              }
            </button>
          </div>
          <div ref={divisorRef} className="divisor">
            <div className="image-cover" ref={coverImageRef} >
              <img
                src={getSpecimenImage()}
                alt="specimen"
                ref={firstImageRef}
                onLoad={() => setResourceData(prevState => ({ ...prevState, specimenLoaded: true}))}
              />
            </div>
          </div>
        </div>
        <div className='comparison-control-section'>
          <input
            type="range"
            min="0"
            max="100"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
          />
          {(specimenImage && externalSource) ? <div className='copyright-content'>
            <span className='copyright-text'>
              {t('identity.doc.specimen.copyright.message1')}
            </span>
            <button
              className='copyright-external-link'
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                window.open(specimenImage, '_blank');
              }}
            >
              {t('identity.doc.specimen.copyright.link')}
            </button>
            <span className='copyright-text'>
              {t('identity.doc.specimen.copyright.message2')}
            </span>
          </div> : <></>}
        </div>
      </div>
    </>
  );
};

export default Comparison;