import { EFields, ERoutePaths, EAddressDataFields, EStatuses } from 'enums';
import { TGenericItem } from 'types';

export const PERMIT = 'permit';
export const UNKNOWN = 'Unknown';
export const LOCAL_STORAGE_LNG = 'i18nextLng';
export const DATASET_STORAGE_KEY = 'dataset_id';
export const DEFAULT_EXPIRE_VALUE = '**********';
export const DRIVING_CATEGORY_DETAILS = 'drivingCategoryDetails';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy';

export const MINIMUM_YEAR = 1900;

export const MAXIMUM_YEAR = 2100;

export const BUTTON_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  OPTION_BUTTON: 'optionButton',
  RADIO_BUTTON: 'radioButton',
  SELECT_OPTION: 'selectOption',
};

export const BUTTON_STYLE = {
  OUTLINE_PRIMARY: 'outline-primary',
  PRIMARY: 'primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  SECONDARY: 'secondary',
  BIG: 'big',
  OUTLINE_BIG: 'big-outline',
  INLINE_BIG: 'big-inline',
  CIRCLE_BUTTON: 'circle-button',
  CIRCLE_GROUP_BUTTONS: 'circle-group-buttons',
  CIRCLE_BUTTON_START: 'circle-group-buttons-start',
  CIRCLE_BUTTON_END: 'circle-group-buttons-end',
};

export const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
};

export const DEFAULT_DASHBOARD_ITEM = {
  items: [],
  page: 1,
  size: 0,
  totalItems: 0,
  active: true,
}

export const DUID_LABEL_MAPPING: TGenericItem = {
  'che-driving_licence-2003': '2003',
  'che-driving_licence-2023': '2023',
}

export const COMPARE_LIST = [
  EFields.lastName,
  EFields.firstName,
  EFields.documentNumber,
];

export const LOADING_DURATION = 10 * 1000; // 10 seconds

export const DEEP_LINK_ALLOWED_URLS = [
  ERoutePaths.root,
  ERoutePaths.signIn,
  ERoutePaths.logOut,
  ERoutePaths.dashboard,
];

export const DEFAULT_DELAY = 500; // 0.5 seconds

export const ENFORCED_REDIRECT_URLS = [
  ERoutePaths.summary,
  ERoutePaths.taBoarding,
  ERoutePaths.identityReview,
  ERoutePaths.addressReview,
  ERoutePaths.a4scanReview,
];

export const SELF_DECLARATION_FIELDS = [
  EAddressDataFields.address,
  EAddressDataFields.firstName,
  EAddressDataFields.lastName,
  EAddressDataFields.maidenName,
  EAddressDataFields.gender,
  EAddressDataFields.birthdate,
  EAddressDataFields.nationality,
  EAddressDataFields.email,
  EAddressDataFields.phone,
];

export const ADDRESS_CHECK_FIELDS = [
  EAddressDataFields.address,
  EAddressDataFields.firstName,
  EAddressDataFields.lastName,
  EAddressDataFields.maidenName,
];

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const fvStatusMessages = {
  [EStatuses.pass]: 'identity.fv.result.label.pass',
  [EStatuses.fail]: 'identity.fv.result.label.fail',
  [EStatuses.notPerformed]: 'identity.fv.result.label.not-performed',
}

export const ldStatusMessages = {
  [EStatuses.pass]: 'identity.ld.result.label.pass',
  [EStatuses.fail]: 'identity.ld.result.label.fail',
  [EStatuses.notPerformed]: 'identity.ld.result.label.not-performed',
}