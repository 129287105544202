import React, { useEffect, useState, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Switch from 'pages/dashboard/Table/Switch';
import Pagination from 'pages/dashboard/Table/Pagination';
import Table from 'components/table/Table';
import { IRootState } from 'store/types';
import columns from 'constants/TableColumns';
import { getSelectedDataset, translateColumnsHeaders } from 'helper';
import * as commonActions from 'store/features/common/actions';
import { DEFAULT_DASHBOARD_ITEM } from 'constants/Static';
import { ISingleButtonProps, TGetTransactionOptions, TTransactionRecord } from 'types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';

const CustomErrorMessage = (props: { t: TFunction }): React.JSX.Element => (
  <tr className='no-connected-dataset-tr'>
    <td className='no-connected-dataset-td'>{props.t('dashboard.not-linked.error-message')}</td>
  </tr>
);

type TExtendedTransaction = TTransactionRecord & {
  transactionButtonElement: React.JSX.Element;
}

type TransactionsProps = {
  getTransactions: Function;
  startReview: Function;
  openSummary: Function;
  reviewStarted: boolean;
};

const Transactions = (props: TransactionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reviewStarted, startReview, openSummary, getTransactions } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard || DEFAULT_DASHBOARD_ITEM;
  const { datasets = [] } = state?.agentProfile?.agent || {};
  const dataset = useMemo(() => getSelectedDataset(datasets), [datasets]);
  const invalidDataset = !dataset?.id || !dataset.accounts.length;
  const tableColumns = dashboard?.active ? columns?.active : columns?.closed;
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!searchValue) {
      getTransactions();
    }
  }, [searchValue, datasets]);

  useEffect(() => {
    if (searchValue) {
      const filteredData = dashboard?.items?.filter((item: Record<string, any>) => (
        Object.values(item).some((value: string) => (
          typeof value === 'string' && value?.toLowerCase().includes(searchValue?.toLowerCase())
        ))
      ));
      if (filteredData) {
        dispatch(commonActions.setDashboardData({
          ...dashboard,
          items: filteredData,
        }));
      }
    }
  }, [searchValue]);

  const transactions = useMemo(() => dashboard?.items.map((transaction) => {
    const baseTransaction = { ...transaction } as TExtendedTransaction;
    const mainOptions: ISingleButtonProps = {
      isActive: true,
      sizeButton: BUTTON_STYLE.BIG,
      isDisabled: reviewStarted,
      radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
      variant: BUTTON_STYLE.OUTLINE_SECONDARY,
      onClick: () => startReview({ transactionCode: transaction.transactionCode }),
      textButton: t('dashboard.open-tab.transaction-review.btn'),
    };
    if (!dashboard?.active) {
      mainOptions.onClick = () => openSummary(transaction);
      mainOptions.textButton = t('dashboard.reviewed-tab.summary.btn');
    }
    baseTransaction.transactionButtonElement = <SingleButton { ...mainOptions } />;
    return baseTransaction;
  }), [dashboard?.items, reviewStarted, props]);

  return (
    <div className='transactions-table'>
      <Table
        data={transactions || []}
        showSearch={true}
        loading={state?.common?.loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        columns={translateColumnsHeaders(tableColumns, t)}
        customHeader={<Switch getTransactions={
          (options: TGetTransactionOptions) => props.getTransactions(options)
        } />}
        customFooter={<Pagination getTransactions={
          (options: TGetTransactionOptions) => props.getTransactions(options)
        }/>}
        CustomErrorMessage={invalidDataset ? CustomErrorMessage : null}
      />
    </div>
  );
};

export default Transactions;