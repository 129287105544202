import moment from 'moment';
import { TFunction } from 'react-i18next';

import { t } from 'i18n/i18n.config';
import { ILocalDate, IOptionType, ISpecimen, IUserAgent, TGenericItem, TTab } from 'types';
import {
  EZones,
  EFields,
  EFileTypes,
  ERoutePaths,
  EReviewStates,
  EDocumentType,
  EWorkflowModules,
  ETransactionState,
  EDocumentMaterial,
} from 'enums';
import { TDataset } from 'store/features/agentManagement/types';
import missingSpecimenEN from 'images/placeholders/missing_specimen_EN.png';
import missingSpecimenDE from 'images/placeholders/missing_specimen_DE.png';
import serviceUnavailableEN from 'images/placeholders/service_unavailable_EN.png';
import serviceUnavailableDE from 'images/placeholders/service_unavailable_DE.png';
import imageNotAvailableEN from 'images/placeholders/image_not_available_EN.png';
import imageNotAvailableDE from 'images/placeholders/image_not_available_DE.png';
import ldNotAvailableEN from 'images/placeholders/ld_not_available_EN.png';
import ldNotAvailableDE from 'images/placeholders/ld_not_available_DE.png';
import fvNotAvailableEN from 'images/placeholders/fv_not_available_EN.png';
import fvNotAvailableDE from 'images/placeholders/fv_not_available_DE.png';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import {
  TDataVerification,
  TDocumentVerification,
  TDrivingCategory,
  TDrivingCategoryValues,
  TFieldResult,
  TManualCheckResult,
  TManualReview,
  TReviewInitialData,
  TTransactionData,
} from 'store/features/reviewResult/types';
import { DATE_FORMAT, MAXIMUM_YEAR, MINIMUM_YEAR, PERMIT, UNKNOWN } from 'constants/Static';

const convertTimestampToDate = (timestamp: number, format = 'YYYY.MM.DD HH:mm') =>
  moment.unix(timestamp).format(format);

const translateColumnsHeaders = (
  columns: any,
  translate: Function,
) => (columns.map((item: any) => {
  const updatedColumns = item.columns?.map((element: any) => (
    {
      ...element,
      Header: translate(element.Header),
    }
  )) || [];
  return {
    ...item,
    Header: translate(item.Header),
    ...(item.columns && { columns: updatedColumns }),
  };
}));

const translateDocTypeCountry = (list: string[], translate: Function) => (
  list.reduce((result: string, word: string, index: number) => {
    result += translate(word);
    if (index !== list.length - 1) {
      result += ' ';
    }
    return result;
  }, '')
);

const getErrorMessage = (transactionState: string, transactionCode: string) => {
  switch (transactionState) {
  case ETransactionState.reviewed:
  case ETransactionState.checkCompleted:
  case ETransactionState.pxlCheckError:
  {
    return [
      { text: t('messages.transaction-code') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.reviewed') },
    ];
  }
  case ETransactionState.inReview: {
    return [
      { text: t('messages.transaction-code') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.inReview') },
    ];
  }
  case ETransactionState.deleted: {
    return [{ text: t('messages.deleted') }];
  }
  case ETransactionState.completed: {
    return [
      { text: t('messages.transaction-code') },
      { text: transactionCode, type: 'bold' },
      { text: t('messages.completed') },
    ];
  }
  case ETransactionState.inError: {
    return [
      { text: t('messages.inError') },
    ];
  }
  default: {
    return !transactionCode ? [{ text: t('dashboard.modal.no-transaction') }]
      : [
        { text: t('messages.transaction-code') },
        { text: transactionCode, type: 'bold' },
        { text: t('messages.notFound') },
      ];
  }
  }
};

const toTitleCase = (input = '') => (
  input.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
);

const getUserAgent = (): IUserAgent => {
  const ua = navigator.userAgent;
  const isChrome = (/Chrome/i.test(ua));
  const isFirefox = (/Firefox/i.test(ua));
  const isSafari = (/Safari/i.test(ua)) && !(/Chrome/i.test(ua)) && !(/CriOS/i.test(ua));
  return { isChrome, isFirefox, isSafari };
};

const getSelectedDataset = (datasets: TDataset[] | undefined) => (
  datasets?.length ? datasets?.find((dataset: TDataset) => dataset.selected) || datasets[0] : null
);

const getNotFoundImage = (language: string, filetype?: EFileTypes) => {
  const fvImages = [EFileTypes.faceAvatar, EFileTypes.faceImage];
  if (language === 'de') {
    if (filetype && fvImages.includes(filetype)) return fvNotAvailableDE;
    if (filetype === EFileTypes.selfieVideo) return ldNotAvailableDE;
    return imageNotAvailableDE;
  }
  if (filetype && fvImages.includes(filetype)) return fvNotAvailableEN;
  if (filetype === EFileTypes.selfieVideo) return ldNotAvailableEN;
  return imageNotAvailableEN;
}

const getNotFoundSpecimen = (language: string) => {
  switch (language) {
  case 'en':
    return missingSpecimenEN;
  case 'de':
    return missingSpecimenDE;
  default:
    return missingSpecimenEN;
  }
}

const getServiceUnavailableImage = (language: string) => {
  switch (language) {
  case 'en':
    return serviceUnavailableEN;
  case 'de':
    return serviceUnavailableDE;
  default:
    return serviceUnavailableEN;
  }
}

const getCountryValue = (value: string, t: TFunction) => {
  const countryData = Countries.find((option) => option.value === value);
  if (countryData?.value) {
    return {
      label: `${t(countryData.label)} (${countryData.value.toUpperCase()})`,
      value: countryData.value,
    };
  }
  return null;
};

const collectExtraDetailOptions = (specimens: ISpecimen[], t: TFunction) => {
  const result = specimens
    .filter((item: ISpecimen) => item.extraDetail?.trim())
    .map((item: ISpecimen) => ({
      label: toTitleCase(item.extraDetail),
      value: item.extraDetail,
    }));
  return result.filter((a, i) => (
    result.findIndex((option) => a.label === option.label) === i)
  );
}

const getExtraDetailValue = (extraDetail: string, extraDetails: IOptionType[] | undefined) => {
  const extraDetailData = extraDetails?.find(({ value }) => value === extraDetail);
  if (extraDetailData?.value) {
    return {
      label: extraDetailData.label,
      value: extraDetailData.value,
    };
  }
  return null;
};

const getDocumentTypeValue = (documentType: string, t: TFunction) => {
  const documentTypeData = DocumentTypes.find(({ value }) => value === documentType);
  if (documentTypeData?.value) {
    return {
      label: t(documentTypeData.label),
      value: documentTypeData.value,
    };
  }
  return null;
};

const formatRejectionReasons = (rejectionReasons: IOptionType[], storedReasons: string[], t: TFunction) => (
  rejectionReasons.reduce((acc: IOptionType[], reason: IOptionType) => {
    if (storedReasons.includes(reason.value)) {
      acc.push({
        label: t(reason.label),
        value: reason.value,
      });
    }
    return acc;
  }, [])
);

const getCountryTranslation = (country: string) => {
  const countryData = Countries.find((option) => option.value === country);
  if (countryData?.alias) return countryData.alias;
  if (countryData) return countryData.label;
  return country;
};

export const getDocTypeByCountryDoc = (docType: string, country: string) => {
  switch (docType) {
  case EDocumentType.id:
    return [getCountryTranslation(country), 'identity.doc.type.id.label'];
  case EDocumentType.passport:
    return [getCountryTranslation(country), 'identity.doc.type.passport.label'];
  case EDocumentType.paperPermit:
    return [getCountryTranslation(country), 'identity.doc.type.paper-permit.label'];
  case EDocumentType.plasticPermit:
    return [getCountryTranslation(country), 'identity.doc.type.plastic-permit.label'];
  case EDocumentType.drivingLicence:
    return [getCountryTranslation(country), 'identity.doc.type.dl.label'];
  case EDocumentType.healthCard:
    return [getCountryTranslation(country), 'identity.doc.type.hc.label'];
  default:
    return ['identity.doc.type.unknown.label'];
  }
};

export const getDocType = (documentType: string, documentCountry: string) => {
  if (documentType && documentCountry) {
    return getDocTypeByCountryDoc(documentType, documentCountry);
  }
  return ['identity.doc.type.unknown.label'];
};

export const getPermitDocumentType = (classifiedDocument: TGenericItem | undefined) => (
  classifiedDocument && Object.values(classifiedDocument).some(side => side.includes(EDocumentMaterial.paper))
    ? EDocumentType.paperPermit : EDocumentType.plasticPermit
);

const getExtractedData = (documentVerification: TDocumentVerification[] | undefined) => {
  const { nfc, mrz, viz, documentTypeClassifier } = documentVerification?.[0] || {};
  const result: TGenericItem = {};
  const {
    classifiedDocumentFirstScan = UNKNOWN,
    classifiedDocumentSecondScan = UNKNOWN,
  } = documentTypeClassifier || {};
  if (classifiedDocumentFirstScan !== UNKNOWN) {
    const [classifiedDocument, side = ''] = classifiedDocumentFirstScan.split(' ');
    result[side] = classifiedDocument;
  }
  if (classifiedDocumentSecondScan !== UNKNOWN) {
    const [classifiedDocument, side] = classifiedDocumentSecondScan.split(' ');
    result[side] = classifiedDocument;
  }
  const documentSubtype = nfc?.documentSubtype || mrz?.documentSubtype || viz?.documentSubtype || '';
  const [, extraDetail = ''] = documentSubtype.split('_');
  const valueMap = {
    documentCountry: nfc?.documentCountry || mrz?.documentCountry || viz?.documentCountry || '',
    documentType: nfc?.documentType || mrz?.documentType || viz?.documentType || '',
    extraDetail,
    classifiedDocument: result,
  };
  if (valueMap.documentType === PERMIT) {
    valueMap.documentType = getPermitDocumentType(result);
  }
  return valueMap;
};

const isFieldCopyAllowed = (
  isValid: boolean,
  editable: boolean,
  detectedFieldEditable : boolean,
  fieldDisabled: boolean,
  value: string,
  comparisonValue : string
) => {
  return (
    isValid && editable && detectedFieldEditable &&
    !fieldDisabled && value && value !== comparisonValue
  );
};

const getDrivingCategoryDetails = (drivingCategory: TDrivingCategory[]) => {
  let edited = false;
  const value = drivingCategory.reduce(( acc: TDrivingCategoryValues[], {
    value, issuingDate, expiryDate, restrictions
  }) => {
    if (value.value || issuingDate.value || expiryDate.value || restrictions.value) {
      edited = value.value !== value.extractedValue || issuingDate.value !== issuingDate.extractedValue
        || expiryDate.value !== expiryDate.extractedValue || restrictions.value !== restrictions.extractedValue;
      acc.push({
        name: value.value,
        issuingDate: issuingDate.value,
        expiryDate: expiryDate.value,
        restrictions: restrictions.value,
      });
    }
    return acc;
  }, []);
  if (!value.length) return null;
  return { edited, value, }
};

const getResultByZone = (
  dataVerification: TDataVerification | undefined, zone: EZones, drivingCategory?: TDrivingCategory[]
) => {
  const result = dataVerification?.fieldNames.reduce((acc: TFieldResult, fieldName: EFields) => {
    if (fieldName === EFields.drivingCategory) return acc;
    const { value = '', extractedValue = '' } = dataVerification.fields?.[fieldName]?.[zone] || {};
    const edited = value !== extractedValue;
    if (value || edited) {
      acc[fieldName] = { value, edited };
    }
    return acc;
  }, {});
  if (result && dataVerification?.fieldNames.includes(EFields.drivingCategory)
    && drivingCategory && drivingCategory.length
  ) {
    const drivingCategoryDetails = getDrivingCategoryDetails(drivingCategory);
    if (drivingCategoryDetails) {
      result.drivingCategoryDetails = drivingCategoryDetails;
    }
  }
  return result;
}

const getTransactionReviewResult = (
  manualCheckResult: TManualCheckResult, modules: string[], data: TReviewInitialData,
): TManualReview => {
  const { rejectionReasons, identityReview, addressReview, a4ScanReview } = manualCheckResult;
  const { faceVerificationReview, livenessDetectionReview, documentValidationReview } = identityReview || {};
  const { reviewResult, email, postProcessing, message } = data;
  const resultToSave: TManualReview = {
    agentIdentifier: email,
    result: reviewResult,
  };
  if (message) {
    resultToSave.message = message;
  }
  if (reviewResult === EReviewStates.REJECTED) {
    resultToSave.postProcessing = postProcessing;
    resultToSave.rejectionReasons = rejectionReasons;
  }
  const [{
    result,
    extraDetail,
    documentType,
    documentCountry,
    drivingCategory,
    dataVerification,
  }] = documentValidationReview;
  const viz = getResultByZone(dataVerification, EZones.viz, drivingCategory);
  const mrz = getResultByZone(dataVerification, EZones.mrz);
  const dataVerificationToSave = {
    ...(viz && Object.keys(viz).length && { viz }),
    ...(mrz && Object.keys(mrz).length && { mrz }),
  };
  if (result) {
    resultToSave.identityReview = {
      ...resultToSave.identityReview,
      documentValidationReview: [{
        result,
        ...(documentCountry && { documentCountry }),
        ...(documentType && { documentType }),
        ...(extraDetail && { extraDetail }),
        ...(Object.keys(dataVerificationToSave).length && { dataVerification: dataVerificationToSave }),
      }]
    };
  }
  if (modules.includes(EWorkflowModules.faceVerification) && faceVerificationReview.result) {
    resultToSave.identityReview = {
      ...resultToSave.identityReview,
      faceVerificationReview: {
        result: faceVerificationReview.result,
      }
    }
  }
  if (modules.includes(EWorkflowModules.livenessDetection) && livenessDetectionReview.result) {
    resultToSave.identityReview = {
      ...resultToSave.identityReview,
      livenessDetectionReview: {
        result: livenessDetectionReview.result,
      },
    };
  }
  if (modules.includes(EWorkflowModules.addressCheck) && addressReview.result) {
    resultToSave.addressReview = { result: addressReview.result };
  }
  if (modules.includes(EWorkflowModules.documentScan) && a4ScanReview.result) {
    resultToSave.a4ScanReview = { result: a4ScanReview.result };
  }
  return resultToSave;
};

const areValuesEqual = (val1: string, val2: string) => val1.toLocaleLowerCase() === val2.toLocaleLowerCase();

const getAllowedTabs = (modules: string[]): TTab[] => {
  const tabs = [
    { name: 'review-header.document.tab.label', url: ERoutePaths.identityReview},
  ];
  if (modules.includes(EWorkflowModules.addressCheck)) {
    tabs.push({ name: 'review-header.address.tab.label', url: ERoutePaths.addressReview });
  }
  if (modules.includes(EWorkflowModules.documentScan)) {
    tabs.push({ name: 'review-header.a4scan.tab.label', url: ERoutePaths.a4scanReview });
  }
  return tabs;
};

const getFileNames = (transactionData: TTransactionData | undefined, filetypes: EFileTypes[]): string[] => {
  const { documentVerification, idRecording, faceLiveness, addressCheck } = transactionData || {};
  const result: string[] = [];
  for (const filetype of filetypes) {
    switch (filetype) {
    case EFileTypes.firstImage:
    case EFileTypes.secondImage:
    case EFileTypes.fullFrameFirstPage:
    case EFileTypes.fullFrameSecondPage:
    case EFileTypes.faceImage:
    case EFileTypes.signatureImage: {
      const file = documentVerification?.[0].files?.find((file) => (file.filetype === filetype));
      result.push(file?.filename || '');
      break;
    }
    case EFileTypes.firstVideo:
    case EFileTypes.secondVideo: {
      const file = idRecording?.files?.find((file) => (file.filetype === filetype));
      result.push(file?.filename || '');
      break;
    }
    case EFileTypes.selfieVideo:
    case EFileTypes.faceAvatar: {
      const file = faceLiveness?.files?.find((file) => (file.filetype === filetype));
      result.push(file?.filename || '');
      break;
    }
    case EFileTypes.addressDocument:
    case EFileTypes.addressCrop: {
      const file = addressCheck?.files?.find((file) => (file.filetype === filetype));
      result.push(file?.filename || '');
      break;
    }
    }
  }
  return result;
};

const getMultipleFilenames = (transactionData: TTransactionData | undefined, filetype: EFileTypes): string[] => {
  const { a4Scan } = transactionData || {};
  switch (filetype) {
  case EFileTypes.a4Scan: {
    const files = a4Scan?.files?.filter((file) => (file.filetype === filetype)) || [];
    return files.map((item) => item.filename);
  }
  default: {
    return [];
  }
  }
};

const convertTimeZone = (date: Date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

const getDateItems = (date: Date | null) => {
  if (date && moment(date).isValid()) {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }
  return { day: 0, month: 0, year: 0, };
};

const getSelectedDate = (value: ILocalDate) => {
  const { day, month, year } = value;
  if ((year && year >= MINIMUM_YEAR && year <= MAXIMUM_YEAR) && month && day) {
    const date = new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
    if (moment(date, DATE_FORMAT, true).isValid()) {
      return convertTimeZone(date);
    }
  }
  return null;
};

export {
  toTitleCase,
  getUserAgent,
  getErrorMessage,
  getSelectedDataset,
  translateDocTypeCountry,
  translateColumnsHeaders,
  getNotFoundImage,
  getNotFoundSpecimen,
  getServiceUnavailableImage,
  getCountryValue,
  getDocumentTypeValue,
  collectExtraDetailOptions,
  formatRejectionReasons,
  getExtractedData,
  getExtraDetailValue,
  isFieldCopyAllowed,
  getTransactionReviewResult,
  areValuesEqual,
  getAllowedTabs,
  convertTimestampToDate,
  getFileNames,
  getMultipleFilenames,
  getDateItems,
  getSelectedDate,
};
