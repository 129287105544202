import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EFields } from 'enums';
import { IRootState } from 'store/types';
import { TFieldResult, TManualReview } from 'store/features/reviewResult/types';
import { DRIVING_CATEGORY_DETAILS } from 'constants/Static';
import { REGULAR_FIELDS } from 'constants/Fields';
import InputField from 'components/input/Input';
import AccordionField from 'components/accordionField/AccordionField';
import { areValuesEqual } from 'helper';

const DataVerificationSection: React.FC = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const { reviewId = '' } = useParams();

  const currentReview = useMemo(() => transactionData.manualReview?.find(
    (review: TManualReview) => review?.history?.reviewId === reviewId
  ), [transactionData.manualReview, reviewId]);

  const dataVerification = useMemo(() => {
    return currentReview?.identityReview?.documentValidationReview?.[0]?.dataVerification;
  }, [currentReview]);

  const renderFields = (mergedFields: string[], source: TFieldResult, target: TFieldResult | undefined) => {
    return mergedFields.map((fieldName) => {
      if (fieldName === DRIVING_CATEGORY_DETAILS) return null;
      const field = EFields[fieldName as keyof typeof EFields];
      const { title } = REGULAR_FIELDS[field] || {};
      const userInputClassName = !source?.[field]?.value || !target?.[field]?.value ? '' :
        areValuesEqual(source?.[field]?.value || '', target?.[field]?.value || '') ? 'pass' : 'fail';
      return (
        <InputField
          key={fieldName}
          disabled
          label={t(title || field)}
          placeholder={source?.[field]?.value || ''}
          userInputClassName={userInputClassName}
        />
      );
    });
  };

  const content = useMemo(() => {
    if (!dataVerification) return null;
    const vizFields = dataVerification.viz || {};
    const mrzFields = dataVerification.mrz || {};
    const fields = Array.from(new Set(Object.keys(vizFields).concat(Object.keys(mrzFields))));
    return (
      <div className="data-verification-accordion-content">
        <div className="data-verification-form">
          <div className="required-fields">
            <h2>{t('identity.doc.data.viz')}</h2>
            {renderFields(fields, vizFields, mrzFields)}
          </div>
          {!!Object.keys(mrzFields).length && <div className="required-fields">
            <h2>{t('identity.doc.data.mrz')}</h2>
            {renderFields(fields, mrzFields, vizFields)}
          </div>}
        </div>
        {Object.keys(vizFields).includes(DRIVING_CATEGORY_DETAILS) && (
          <>
            <div className="line" />
            <div className="driving-license-form">
              <h2>{t('identity.doc.data.driving-category')}</h2>
              {
                vizFields.drivingCategoryDetails?.value.map((category: { [key: string]: string }) => {
                  let categoryNumber = 9;
                  return Object.entries(category).map(([key, value]) => (
                    <InputField
                      key={key}
                      disabled
                      label={`${categoryNumber++}`}
                      placeholder={value}
                    />
                  ));
                })
              }
            </div>
          </>
        )}
      </div>
    );
  }, [dataVerification]);

  return (
    <div className="data-verification-content">
      <AccordionField
        title={t('identity.doc.data.title')}
        body={content}
        className={'accordion-container'}
        showStatus={!!currentReview?.identityReview}
        eventKey={1}
        preventToggle={!currentReview?.identityReview}
      />
    </div>
  );
};

export default DataVerificationSection;
