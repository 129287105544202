import { AgentActions, TAgent } from 'store/features/agentManagement/types';

export const setAgent = (value: TAgent) => ({
  type: AgentActions.setAgent,
  value,
});

export const setDefaultDataset = (value: number) => ({
  type: AgentActions.setDefaultDataset,
  value,
});
