import React from 'react';

interface IInputProps {
  placeholder: string;
  label: string;
  disabled?: boolean;
}

const TextAreaField = (props: IInputProps) => {
  const { placeholder, label, disabled } = props;

  return (
    <div className="input-container">
      <span className="placeholder">
        {label}
      </span>
      <textarea
      className="user-input"
      disabled={disabled}
      placeholder={placeholder}
      />
    </div>);
};

export default TextAreaField;