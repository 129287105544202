import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import AccordionMenu from 'components/accordionMenu/AccordionMenu';

type TBurgerMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

const BurgerMenu = (props: TBurgerMenuProps) => {
  const { isOpen, onClose } = props;

  return (
    <div className='burger-menu-main-component'>
      <Menu
        isOpen={isOpen}
        right
        customBurgerIcon={false}
        onClose={onClose}
      >
        <div className="left-menu-container">
          <AccordionMenu />
        </div>
      </Menu>
    </div>
  );
};

export default BurgerMenu;
