import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import FieldComparison from 'pages/identityReview/documentValidation/dataVerification/fieldComparison/FieldComparison';
import { getDrivingCategoriesValues, getValues } from 'pages/identityReview/helper';
import { EComponentTypes, EFields } from 'enums';
import { IRootState } from 'store/types';
import { GetRequiredFieldsAPI } from 'helper/api/route';
import { TFields, TFieldData, TFiledItemResult } from 'store/features/reviewResult/types';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import DrivingField from 'pages/identityReview/documentValidation/dataVerification/drivingField/DrivingField';
import { REGULAR_FIELDS } from 'constants/Fields';

interface IPersonalInfoProps {
  documentType: string;
  documentCountry: string;
  extraLabel?: string;
}

const DataVerification = (props: IPersonalInfoProps) => {
  const { documentCountry, documentType, extraLabel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData, transactionCode, manualCheckResult } = state.reviewResult;
  const { identityReview } = manualCheckResult || {};
  const { documentValidationReview } = identityReview || {};
  const [{ dataVerification, specimens = [], duid, specimenNotFound }] = documentValidationReview || [];
  const { documentVerification } = transactionData || {};
  const { zones, fields = {}, fieldNames = [] } = dataVerification || {};

  useEffect(() => {
    if (documentCountry && documentType && (duid || specimenNotFound)) {
      GetRequiredFieldsAPI(transactionCode, {
        documentCountry: documentCountry.toLowerCase(), documentType, extraLabel,
      }).then((response) => {
        const dataVerificationValues = getValues(
          response.data,
          documentValidationReview?.[0],
          documentVerification?.[0],
        );
        if (!documentValidationReview[0].drivingCategory) {
          const drivingCategory = getDrivingCategoriesValues(response.data, documentVerification?.[0]);
          dispatch(reviewResultActions.setDrivingCategory(drivingCategory));
        }
        dispatch(reviewResultActions.setDocumentValidation({
          dataVerification: dataVerificationValues,
        }));
      }).catch(() => {
        dispatch(reviewResultActions.setDocumentValidation({
          dataVerification: {
            fields: dataVerification?.fields ?? {},
            zones: [],
            fieldNames: []
          },
        }));
      });
    } else {
      dispatch(reviewResultActions.setDocumentValidation({
        dataVerification: {
          fields: dataVerification?.fields ?? {},
          zones: [],
          fieldNames: []
        },
      }));
    }
  }, [duid, specimenNotFound, documentCountry, documentType, extraLabel]);

  const handleFieldChange = (fieldKey: keyof TFields, updatedFieldData: TFieldData) => {
    dispatch(reviewResultActions.setDocumentValidation({
      dataVerification: {
        fields: {
          ...dataVerification?.fields,
          [fieldKey]: {
            ...dataVerification?.fields[fieldKey],
            ...updatedFieldData,
          },
        },
        zones: dataVerification?.zones ?? [],
        fieldNames: dataVerification?.fieldNames ?? [],
      }
    }));
  };

  const screenIsApproved = !!(documentCountry && documentType && (duid || specimenNotFound));

  return (
    <div className='data-verification'>
      {!screenIsApproved || !dataVerification || !zones || !zones.length ? (
        <h6 className='no-data-verification'>{t('identity.doc.data.no-doc-error.message')}</h6>
      ) : (
        <>
          <h2>{t('identity.doc.data.title')}</h2>
          <div className='verification-table'>
            <div className={`verification-filed-row ${zones.length === 1 ? 'single': ''}`}>
              {zones.map((zone: string) => (
                <span key={uuid()}>{zone.toUpperCase()}</span>
              ))}
            </div>
            <div className='verification-row'>
              {fieldNames.map((field, index) => {
                if (field === EFields.drivingCategory) return null;
                const { title, componentType, getOptions, getPlaceholder } = REGULAR_FIELDS?.[field] || {};
                const [leftZone, rightZone] = zones;
                return <React.Fragment key={index}>
                  <FieldComparison
                    transactionCode={transactionCode}
                    field={field}
                    componentType={componentType ?? EComponentTypes.input}
                    translationKey={title ?? field}
                    leftData={fields[field]?.[leftZone] || {} as TFiledItemResult}
                    rightData={fields[field]?.[rightZone]}
                    selectOptions={getOptions ? getOptions(t, specimens) : []}
                    setPersonalInfo={(data: TFieldData) => handleFieldChange(field, data)}
                    getPlaceholder={getPlaceholder}
                  />
                </React.Fragment>
              })}
            </div>
          </div>
          {fieldNames.includes(EFields.drivingCategory) && <DrivingField />}
        </>
      )}
    </div>
  );
};

export default DataVerification;
