import { TAgentProfile, TAgentActions } from 'store/features/agentManagement/types';
import { ICommon, TCommonActions } from 'store/features/common/types';
import { IReviewResult } from 'store/features/reviewResult/types';

export enum rootActions {
  reset = 'RESET',
  resetSession = 'RESET_SESSION'
}

export interface IRootState {
  common?: ICommon;
  agentProfile?: TAgentProfile;
  reviewResult: IReviewResult;
}

export type TRootActions =
  TAgentActions |
  TCommonActions |
  { type: rootActions.reset; value: null } |
  { type: rootActions.resetSession; value: null };
