import { PERMIT } from 'constants/Static';
import { EFields, EZones } from 'enums';
import { getPermitDocumentType } from 'helper';

import {
  TDataVerification,
  TDocumentValidationReview,
  TDocumentVerification,
  TDrivingCategory,
  TDrivingCategoryDetail,
  TRequiredFields,
} from 'store/features/reviewResult/types';
import { IOptionType } from 'types';

const removeZones = (allowedZones: EZones[], zonesToRemove: EZones[]) => (
  allowedZones.filter((zone) => !zonesToRemove.includes(zone))
);

const getZones = (requiredFields: TRequiredFields, extractedData: TDocumentVerification | undefined) => {
  const allowedZones: EZones[] = [EZones.viz];
  Object.keys(requiredFields).forEach((f) => {
    const field = EFields[f as keyof typeof EFields];
    if (requiredFields[field]) {
      const zones = Object.keys(requiredFields[field] || {}) as EZones[];
      zones.forEach((zone) => {
        if (!allowedZones.includes(zone)) {
          allowedZones.push(zone);
        }
      });
    }
  });
  if (extractedData?.nfc) {
    return removeZones(allowedZones, [EZones.mrz, EZones.barcode]);
  } else if (extractedData?.barcode) {
    return removeZones(allowedZones, [EZones.mrz, EZones.nfc]);
  }
  return removeZones(allowedZones, [EZones.nfc, EZones.barcode]);
};

const mapCountryOptions = (options: IOptionType[], t: (label: string) => string) => {
  return options.map((option) => ({
    label: `${t(option.label)} (${option.value.toUpperCase()})`,
    value: option.value,
  }));
};

const mapOptions = (options: IOptionType[], t: (label: string) => string) => {
  return options.map((option) => ({
    label: t(option.label),
    value: option.value,
  }));
};

const getValues = (
  requiredFields: TRequiredFields,
  documentValidationReview: TDocumentValidationReview | undefined,
  extractedData: TDocumentVerification | undefined,
) => {
  const result: TDataVerification = {
    zones: [],
    fieldNames: [],
    fields: {},
  };
  const zones = getZones(requiredFields, extractedData);
  result.zones = zones;
  const { dataVerification, classifiedDocument } = documentValidationReview || {};
  result.fields = JSON.parse(JSON.stringify(dataVerification?.fields || {}));
  Object.keys(requiredFields).forEach((f) => {
    const field = f as keyof typeof EFields;
    if (field === EFields.drivingCategory) {
      result.fieldNames.push(EFields[field]);
      return;
    }
    result.fieldNames.push(EFields[field]);
    if (requiredFields[field]) {
      result.zones?.forEach((zone) => {
        let extractedValue = extractedData?.[zone]?.[field] || '';
        if (field === EFields.documentType && extractedValue === PERMIT) {
          extractedValue = getPermitDocumentType(classifiedDocument)
        }
        if (field === EFields.documentSubtype) {
          [, extractedValue] = extractedValue.split('_');
        }
        if (field === EFields.gender) {
          if (extractedValue === 'male') extractedValue = 'm';
          if (extractedValue === 'female') extractedValue = 'f';
        }
        const editable = !!requiredFields[field]?.[zone] || false;
        const optional = requiredFields[field]?.[zone]?.optional || false;
        if (!editable) extractedValue = '';
        const initialData = {
          zone,
          value: extractedValue,
          extractedValue,
          disabled: [EZones.nfc, EZones.barcode].includes(zone),
          editable,
          optional,
        };
        if (!result.fields?.[field]) {
          result.fields[field] = { [zone]: initialData };
        } else if (!result.fields?.[field]?.[zone]) {
          result.fields[field] = {
            ...result.fields[field],
            [zone]: initialData,
          };
        } else {
          result.fields[field] = {
            ...result.fields[field],
            [zone]: {
              ...result.fields[field]?.[zone],
              editable,
              optional,
            },
          };
        }
      });
    }
  });
  return result;
};

const getDrivingCategoriesValues = (
  requiredFields: TRequiredFields,
  extractedData?: TDocumentVerification
) => {
  const drivingCategories: TDrivingCategory[] = [];

  Object.keys(requiredFields).forEach((f) => {
    const field = f as keyof typeof EFields;
    if (requiredFields[field]) {
      if (field === EFields.drivingCategory) {
        const { drivingCategoryDetails } = extractedData?.viz || {};
        drivingCategoryDetails?.forEach(
          (drivingCategoryDetail: TDrivingCategoryDetail) => {
            drivingCategories.push({
              value: {
                value: drivingCategoryDetail?.name || '',
                extractedValue: drivingCategoryDetail?.name || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              issuingDate: {
                value: drivingCategoryDetail?.issuingDate || '',
                extractedValue: drivingCategoryDetail?.issuingDate || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              expiryDate: {
                value: drivingCategoryDetail?.expiryDate || '',
                extractedValue: drivingCategoryDetail?.expiryDate || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              restrictions: {
                value: drivingCategoryDetail?.restrictions || '',
                extractedValue: drivingCategoryDetail?.restrictions || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
            });
          }
        );
      }
    }
  });

  return drivingCategories;
};

const hasActiveAcceptanceCheck = (acceptanceChecks: any, acceptanceCheckNames: string[], excludedCheckName: string) => {
  const remainingCheckNames = acceptanceCheckNames.filter(
    checkName => checkName !== excludedCheckName
  );
  return remainingCheckNames.some(
    (checkName) => acceptanceChecks[checkName]
  );
};

export {
  getZones,
  removeZones,
  mapOptions,
  getValues,
  getDrivingCategoriesValues,
  mapCountryOptions,
  hasActiveAcceptanceCheck,
};
