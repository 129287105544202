import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import AddressImages from 'pages/addressReview/addressImages/AddressImages';
import AddressData from 'pages/addressReview/addressData/AddressData';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { EAnswers, EFileTypes } from 'enums';
import { IRootState } from 'store/types';
import MultipleSelect from 'components/select/MultipleSelect';
import { formatRejectionReasons } from 'helper';
import { AddressReviewRejectionReasons } from 'constants/Reasons';
import { IOptionType } from 'types';
import Modal from 'components/modal/Modal';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';

const AddressReview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, manualCheckResult } = state.reviewResult;
  const { rejectionReasons, addressReview } = manualCheckResult;
  const [filename, setFilename] = useState('');
  const addressReviewReasons = formatRejectionReasons(
    AddressReviewRejectionReasons, rejectionReasons.addressReview, t,
  );

  const rejectionReasonsProps = {
    value: addressReviewReasons,
    handler: (selected: IOptionType[]) => dispatch(reviewResultActions.setRejectionReasons({
      ...rejectionReasons,
      addressReview: selected?.map((reason: IOptionType) => reason.value) || []
    })),
    placeholder: t('address.rejection-reason-placeholder'),
    options: AddressReviewRejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(reviewResultActions.setAddressReview({
      result: event.target.value,
    }));
    if (event.target.value === EAnswers.yes) {
      dispatch(reviewResultActions.setRejectionReasons({ ...rejectionReasons, addressReview: [] }));
    }
  }

  return <>
    <div className='address-review-content'>
      <h1>{t('address.heading')}</h1>
      <div className='address-main-content'>
        <div className='address-images-content'>
          <AddressImages setFilename={setFilename} />
        </div>
        <div className='address-data-content'>
          <AddressData />
        </div>
      </div>
      <div className='address-result-content'>
        <div className="review-buttons">
          <h2 className="description">{t('review-footer.address.question.title')}</h2>
          <div className='result-reasons-section'>
            <div className='answer-section'>
              <Form.Check
                inline
                label={t('review-footer.answer-yes.title')}
                value={EAnswers.yes}
                name='address.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                checked={addressReview.result === EAnswers.yes}
              />
              <Form.Check
                inline
                label={t('review-footer.answer-no.title')}
                value={EAnswers.no}
                name='address.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                checked={addressReview.result === EAnswers.no}
              />
            </div>
            <div className='select-reasons'>
              <MultipleSelect
                disabled={!(addressReview.result === EAnswers.no)}
                {...rejectionReasonsProps}
              />
            </div>
          </div>
        </div>
      </div>
      {!!filename && <Modal
        show={!!filename}
        onHide={() => setFilename('')}
      >
        <div className='address-image-wrapper-container'>
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={EFileTypes.addressDocument}
            filename={filename}
            enableZoom={true}
            original={true}
          />
        </div>
      </Modal>}
    </div>
  </>
};

export default AddressReview;
