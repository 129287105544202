import { EDrivingCategoryFields, EFields, EFileTypes, EReviewStates, EZones } from 'enums';
import { IOptionType, TGenericItem, TReturnCodes, TReviewHistory } from 'types';

export enum ReviewResultActions {
  setTransactionData = 'SET_TRANSACTION_DATA',
  setRejectionReasons = 'SET_REJECTION_REASONS',
  setDocumentValidation = 'SET_DOCUMENT_VALIDATION',
  setAcceptanceChecks = 'SET_ACCEPTANCE_CHECKS',
  setA4ScanReview = 'SET_A4_SCAN_REVIEW',
  setAddressReview = 'SET_ADDRESS_REVIEW',
  setFaceVerificationReview = 'SET_FACE_VERIFICATION_REVIEW',
  setLivenessDetectionReview = 'SET_LIVENESS_DETECTION_REVIEW',
  setSpecimens = 'SET_SPECIMENS',
  setDrivingCategory = 'SET_DRIVING_CATEGORY',
}

interface IResultFile {
  filetype: EFileTypes;
  filename: string;
  SHA512: string;
}

type TManualCheckFilesItem = {
  files: IResultFile[];
};

type TDevice = {
  model: string;
  osVersion: string;
}

export interface IAddress {
  street?: string;
  houseNumber?: string;
  addressLine2?: string;
  countryCode?: string;
  city?: string;
  zipCode?: string;
}

export interface IPersonalDetails {
  firstName?: string;
  lastName?: string;
  address?: IAddress;
  maidenName?: string;
  gender?: string;
  birthdate?: string;
  phone?: string;
  country?: string;
  nationality?: string;
  email?: string;
}

type TUserAuthentication = {
  channel?: string;
  phoneNumber?: string;
  emailAddress?: string
};

export type TDrivingCategoryDetail = {
  name: string;
  issuingDate: string;
  expiryDate: string;
  restrictions: string;
}

type TDocumentVerificationFields = {
  [field in EFields]?: string;
} & {
  drivingCategoryDetails: TDrivingCategoryDetail[];
}

export type TDocumentVerification = {
  files?: IResultFile[];
  documentTypeClassifier?: {
    classifiedDocumentFirstScan?: string;
    classifiedDocumentSecondScan?: string;
  };
  mrz?: TDocumentVerificationFields;
  viz?: TDocumentVerificationFields;
  barcode?: TDocumentVerificationFields;
  nfc?: TDocumentVerificationFields;
  calculatedAge?: number;
};

type TLivenessFaceVerification = {
  result: string;
  threshold: number;
  confidenceLevel: number;
  processId: string;
  FSTID: string;
}

type TFaceLiveness = {
  files: Array<IResultFile>;
  livenessCheck?: TLivenessFaceVerification;
  faceVerification?: TLivenessFaceVerification;
};

export type ITAddressCoordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: string;
}

export type TAddressCheck = {
  files?: IResultFile[];
  addressCoordinates?: ITAddressCoordinates;
  address?: IAddress;
  firstName?: string;
  lastName?: string;
  maidenName?: string;
  SettlementId?: number;
  CorrectionType?: string;
  TotalScore?: string;
};

export type TRejectionReasons = {
  imageQuality: string[];
  document: string[];
  documentRecording: string[];
  faceVerification: string[];
  livenessDetection: string[];
  addressReview: string[];
  a4ScanReview: string[];
}

export type TRequiredFields = {
  [field in EFields]?: {
    [zone in EZones]?: {
      optional: boolean;
    }
  }
};

export type TFiledItemResult = {
  value: string;
  extractedValue: string;
  zone: EZones;
  editable: boolean;
  disabled: boolean;
  optional: boolean;
};

export type TFieldData = {
  [zone in EZones]?: TFiledItemResult;
};


export type TFields = {
  [field in EFields]?: TFieldData;
};

export type TDataVerification = {
  zones: EZones[];
  fieldNames: EFields[];
  fields: TFields;
};

export type TDrivingCategory = {
  [key in EDrivingCategoryFields]: {
    value: string;
    extractedValue: string;
    zone: EZones;
    optional: boolean;
  };
}

export type TAcceptanceChecks = {
  documentValid?: boolean;
  documentPresented?: boolean;
  documentPhysicalSigns?: boolean;
  documentClearlyVisible?: boolean;
  livenessRealVideo?: boolean;
  faceVerificationPhysicalSigns?: boolean;
  faceVerificationSamePerson?: boolean;
};

export type TResultItem = {
  result?: string;
};

export type TSpecimen = {
  duid: string;
  extraDetail: string;
  externalSource: boolean;
  front?: string;
  back?: string;
};

export type TDocumentValidationReview = TResultItem & {
  documentCountry?: string;
  documentType?: string;
  extraDetail?: string;
  dataVerification?: TDataVerification;
  drivingCategory?: TDrivingCategory[];
  specimens?: TSpecimen[];
  extraDetails?: IOptionType[];
  specimenNotFound?: boolean;
  duid?: string;
  classifiedDocument?: TGenericItem;
};

export type TIdentityReview = {
  documentValidationReview: TDocumentValidationReview[];
  faceVerificationReview: TResultItem;
  livenessDetectionReview: TResultItem;
};
type TDataVerificationResult = {
  [zone in EZones]?: TFieldResult;
};

export type TManualReview = {
  result: EReviewStates;
  agentIdentifier: string;
  reviewId?: string;
  history?: TReviewHistory;
  message?: string;
  postProcessing?: boolean;
  rejectionReasons?: {
    imageQuality?: string[];
    document?: string[];
    documentRecording?: string[];
    faceVerification?: string[];
    livenessDetection?: string[];
    addressReview?: string[];
    a4ScanReview?: string[];
    externalVerification?: string[];
  };
  identityReview?: {
    documentValidationReview?: {
      result?: string;
      documentCountry?: string;
      documentType?: string;
      extraDetail?: string;
      dataVerification?: TDataVerificationResult;
    }[];
    faceVerificationReview?: { result: string };
    livenessDetectionReview?: { result: string };
  };
  addressReview?: { result: string };
  a4ScanReview?: { result: string };
};

export type TManualCheckResult = {
  rejectionReasons: TRejectionReasons;
  identityReview: TIdentityReview;
  acceptanceChecks: TAcceptanceChecks;
  addressReview: TResultItem;
  a4ScanReview: TResultItem;
};

export type TTransactionData = {
  flaggedAsFraud: boolean;
  modules: string[];
  device: TDevice;
  minimumAge?: number;
  initialPersonalData?: IPersonalDetails;
  userAuthentication?: TUserAuthentication;
  selfDeclaration?: IPersonalDetails;
  returnCodes?: TReturnCodes;
  documentVerification?: TDocumentVerification[];
  idRecording?: TManualCheckFilesItem;
  faceLiveness?: TFaceLiveness;
  addressCheck?: TAddressCheck;
  a4Scan?: TManualCheckFilesItem;
  manualReview?: TManualReview[];
};

export type TLockTransactionRes = {
  transactionCode: string;
  orderId: string;
  transactionState: string;
}

export interface IReviewResult {
  transactionCode: string;
  transactionData: TTransactionData;
  manualCheckResult: TManualCheckResult;
}

export type TDrivingCategoryValues = {
  name: string;
  issuingDate: string;
  expiryDate: string;
  restrictions: string;
};

type TFieldResultBase = {
  [field in EFields]?: {
    value: string;
    edited: boolean
  };
}

export type TFieldResult = TFieldResultBase & {
  drivingCategoryDetails?: {
    edited: boolean;
    value: TDrivingCategoryValues[];
  },
};

export type TReviewInitialData = {
  reviewResult: EReviewStates;
  email: string;
  postProcessing: boolean;
  message: string;
};

export type TReviewResultActions =
  {
    type: ReviewResultActions.setTransactionData;
    value: { transactionData: TTransactionData; transactionCode: string; }
  } |
  { type: ReviewResultActions.setRejectionReasons; value: TRejectionReasons } |
  { type: ReviewResultActions.setDocumentValidation; value: TDocumentValidationReview } |
  { type: ReviewResultActions.setAcceptanceChecks; value: TAcceptanceChecks } |
  { type: ReviewResultActions.setA4ScanReview; value: TResultItem } |
  { type: ReviewResultActions.setAddressReview; value: TResultItem } |
  { type: ReviewResultActions.setFaceVerificationReview; value: TResultItem } |
  { type: ReviewResultActions.setLivenessDetectionReview; value: TResultItem } |
  { type: ReviewResultActions.setSpecimens; value: TSpecimen[] } |
  { type: ReviewResultActions.setDrivingCategory; value: TDrivingCategory[] };
