import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IRootState } from 'store/types';
import { ADDRESS_CHECK_FIELDS, SELF_DECLARATION_FIELDS } from 'constants/Static';
import { generateData, getModifiedValue } from 'pages/addressReview/helper';

const AddressData = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const { addressCheck, selfDeclaration } = transactionData || {};

  const selfDeclarationData = useMemo(() => (
    generateData(selfDeclaration, addressCheck, SELF_DECLARATION_FIELDS, true)
  ), []);

  const addressCheckData = useMemo(() => (
    generateData(selfDeclaration, addressCheck, ADDRESS_CHECK_FIELDS, false)
  ), []);

  return <>
    <div className='self-declaration-data-content'>
      <h2 className='self-declaration-title'>{t('address.self-dec.title')}</h2>
      {
        selfDeclarationData.map((item, index) => {
          return <div key={index} className="input-container">
            <span className="placeholder" >
              {t(item.translationKey)}
            </span>
            <input
              className={(item.equals ? '' : ' equals-style')}
              type="text"
              value={getModifiedValue(item, t)}
              disabled={true}
            />
          </div>
        })
      }
    </div>
    <div className='extracted-address-content'>
      <h2 className='extracted-address-title'>{t('address.api.title')}</h2>
      {
        addressCheckData.map((item, index) => {
          return <div key={index} className="input-container">
            <span className="placeholder" >
              {t(item.translationKey)}
            </span>
            <input
              className="user-input"
              type="text"
              value={getModifiedValue(item, t)}
              disabled={true}
            />
          </div>
        })
      }
    </div>
  </>;
};

export default AddressData;