import { TFunction } from 'react-i18next';

import { EFields, EValidationTypes, EComponentTypes } from 'enums';
import Nationalities from 'constants/Nationalities.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import GenderOptions from 'constants/GenderOptions.json';
import Countries from 'constants/Countries.json';
import { IOptionType, ISpecimen } from 'types';
import { mapCountryOptions, mapOptions } from 'pages/identityReview/helper';
import { collectExtraDetailOptions } from 'helper';

type TFieldsData = {
  [field in EFields]?: {
    maxLength: number;
    validationType: EValidationTypes;
    componentType: EComponentTypes;
    title: string;
    getOptions?: (t: TFunction, specimens?: ISpecimen[]) => IOptionType[];
    getPlaceholder?: (value: string, selectOptions: IOptionType[]) => string;
  };
};

export const REGULAR_FIELDS: TFieldsData = {
  [EFields.firstName]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.first-name',
  },
  [EFields.lastName]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.last-name',
  },
  [EFields.maidenName]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.maiden-name',
  },
  [EFields.marriedName]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.married-name',
  },
  [EFields.fullName]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.full-name',
  },
  [EFields.documentNumber]: {
    maxLength: 30,
    validationType: EValidationTypes.stringAndNumber,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.document-number',
  },
  [EFields.zemisNumber]: {
    maxLength: 50,
    validationType: EValidationTypes.stringAndNumber,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.zemis-number',
  },
  [EFields.gender]: {
    maxLength: 50,
    validationType: EValidationTypes.gender,
    componentType: EComponentTypes.select,
    getOptions: (t: TFunction) => mapOptions(GenderOptions, t),
    title: 'identity.doc.data.gender',
  },
  [EFields.dateOfBirth]: {
    maxLength: 50,
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-birth',
  },
  [EFields.expirationDate]: {
    maxLength: 50,
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.expiration-date',
  },
  [EFields.dateOfIssue]: {
    maxLength: 50,
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-issue',
  },
  [EFields.dateOfEntry]: {
    maxLength: 50,
    validationType: EValidationTypes.date,
    componentType: EComponentTypes.datepicker,
    title: 'identity.doc.data.date-of-entry',
  },
  [EFields.documentType]: {
    maxLength: 50,
    validationType: EValidationTypes.select,
    componentType: EComponentTypes.select,
    getOptions: (t: TFunction) => mapOptions(DocumentTypes, t),
    getPlaceholder: (value: string, selectOptions: IOptionType[] | undefined) => (
      selectOptions?.find((option) => option.value === value)?.label || value
    ),
    title: 'identity.doc.data.document-type',
  },
  [EFields.documentSubtype]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.select,
    getOptions: (t: TFunction, specimens?: ISpecimen[]) => collectExtraDetailOptions(specimens || [], t),
    title: 'identity.doc.data.document-subtype',
  },
  [EFields.documentCountry]: {
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.select,
    getOptions: (t: TFunction) => mapCountryOptions(Countries, t),
    title: 'identity.doc.data.document-country',
  },
  [EFields.nationality]:{
    maxLength: 50,
    validationType: EValidationTypes.onlyString,
    componentType: EComponentTypes.select,
    getOptions: (t: TFunction) => mapCountryOptions(Countries.concat(Nationalities), t),
    title: 'identity.doc.data.nationality',
  },
  [EFields.placeOfBirth]: {
    maxLength: 50,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.place-of-birth',
  },
  [EFields.overallRestriction]: {
    maxLength: 50,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.overall-restriction',
  },
  [EFields.endorsements]: {
    maxLength: 50,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.endorsements',
  },
  [EFields.drivingCategories]: {
    maxLength: 50,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.driving-categories',
  },
  [EFields.authority]: {
    maxLength: 50,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.authority',
  },
  [EFields.address]: {
    maxLength: 200,
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    title: 'identity.doc.data.address',
  },
  [EFields.kantonReferenceNumber]: {
    title: 'identity.doc.data.kanton-reference-number',
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    maxLength: 50
  },
  [EFields.personalIdentificationNumber]: {
    title: 'identity.doc.data.personal-identification-number',
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    maxLength: 50
  },
  [EFields.institutionNumber]: {
    title: 'identity.doc.data.institution-number',
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    maxLength: 50
  },
  [EFields.restrictions]: {
    title: 'identity.doc.data.restrictions',
    validationType: EValidationTypes.string,
    componentType: EComponentTypes.input,
    maxLength: 50
  }
}
