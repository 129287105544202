import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'store/types';
import * as commonActions from 'store/features/common/actions';
import { DEFAULT_DASHBOARD_ITEM } from 'constants/Static';

const Switch = (props: { getTransactions: Function }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard || DEFAULT_DASHBOARD_ITEM;

  const onSwitch = (isActive: boolean) => () => {
    if (isActive !== dashboard?.active) {
      dispatch(commonActions.setDashboardData({ ...dashboard, active: isActive }));
      props.getTransactions({ active: isActive, specificPage: 1 });
    }
  };

  return (
    <div className='transaction-table-switch'>
      <Button
        disabled={false}
        className='show-active-btn'
        onClick={onSwitch(true)}
        variant={dashboard?.active ? 'dark' : 'outline-dark'}
      >
        {t('dashboard.open.tab')}
      </Button>
      <Button
        disabled={false}
        className='show-closed-btn'
        onClick={onSwitch(false)}
        variant={dashboard?.active ? 'outline-dark' : 'dark'}
      >
        {t('dashboard.reviewed.tab')}
      </Button>
    </div>
  );
};

export default Switch;
