
import React from 'react';

interface IInputProps {
  className?: string;
  handler?: Function;
  placeholder: string;
  type?: string;
  label: string;
  ref?: React.LegacyRef<HTMLDivElement>;
  disabled?: boolean;
  userInputClassName?: string;
}

const InputField = (props: IInputProps) => {
  const {
    type = 'text',
    className = 'input-container',
    handler, placeholder, label, ref, userInputClassName, disabled,
  } = props;

  return (
    <div className={className} ref={ref}>
      <input
        className={`user-input ${userInputClassName}`}
        type={type}
        placeholder={placeholder}
        onChange={(e) => handler && handler(e.target.value)}
        disabled={disabled}
      />
      <span
        className="placeholder"
      >
        {label}
      </span>
    </div>);
};

export default InputField;