import axios from 'axios';
import React, { useRef, useState, useEffect, useMemo, MutableRefObject } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { getNotFoundImage, getNotFoundSpecimen, getServiceUnavailableImage } from 'helper';
import { GetResourceAPI } from 'helper/api/route';
import { TResourceParams } from 'types';
import { ITAddressCoordinates } from 'store/features/reviewResult/types';
import { EFileTypes, EUnits } from 'enums';
import { getRectanglePosition } from 'pages/addressReview/helper';
import { LOADING_DURATION } from 'constants/Static';

interface IImageWrapper {
  transactionCode: string;
  filetype: EFileTypes;
  filename?: string;
  setFilename?: (filename: string) => void;
  enableZoom?: boolean;
  coordinates?: ITAddressCoordinates;
  original?: boolean;
  specimenImage?: string;
}

const ImageWrapper = (props: IImageWrapper) => {
  const { i18n } = useTranslation();
  const containerRef = useRef() as MutableRefObject<HTMLImageElement>;
  const imageRef = useRef() as MutableRefObject<HTMLImageElement>;
  const {
    specimenImage, transactionCode, filename, setFilename,
    enableZoom = false, original = false, coordinates, filetype,
  } = props;
  const [documentImage, setDocumentImage] = useState('');
  const [resourceData, setResourceData] = useState({
    imageLoaded: false,
    imageNotFound: false,
    imageUnavailable: false,
  });
  const [position, setPosition] = useState({ width: 0, height: 0, left: 0, top: 0 });
  const [originalDimensions, setOriginalDimensions] = useState({ originalWidth: 0, originalHeight: 0 });
  const coordinatesAreValid = useMemo(() => position.width > 0 && position.height > 0, [position]);
  const resourceDataRef = useRef(resourceData);

  useEffect(() => {
    resourceDataRef.current = resourceData;
  }, [resourceData]);

  const setupZoom = (rest: any) => {
    rest.doubleClick.disabled = true;
    rest.pan.paddingSize = 0;
    rest.options.minScale = 1;
    rest.options.maxScale = 3;
  };

  const onLoad = (setPositionX: Function, setPositionY: Function, setDefaultState: Function) => {
    setDefaultState();
    setPositionX(0, 0, 'easeOut');
    setPositionY(0, 0, 'easeOut');
    setPosition(getRectanglePosition(imageRef, containerRef, coordinates, originalDimensions));
  };

  useEffect(() => {
    setResourceData({ imageLoaded: false, imageNotFound: false, imageUnavailable: false });
    const source = axios.CancelToken.source();
    if ('specimenImage' in props) {
      if (!specimenImage) {
        setResourceData(prevState => ({ ...prevState, imageLoaded: true, imageNotFound: true }));
        return;
      }
      const specimenImageData = new Image();
      specimenImageData.src = specimenImage || '';
      specimenImageData.onload = () => {
        setResourceData(prevState => ({ ...prevState, imageLoaded: true }));
      };
      specimenImageData.onerror = () => {
        setResourceData(prevState => ({ ...prevState, imageLoaded: true, imageUnavailable: true }));
      };
      const intervalId: NodeJS.Timeout = setInterval(() => {
        if (!resourceDataRef.current.imageLoaded) {
          setResourceData(prevState => ({ ...prevState, imageUnavailable: true }));
        }
      }, LOADING_DURATION);
      return () => clearTimeout(intervalId);
    } else if (!filename) {
      setResourceData((prevState) => ({
        ...prevState,
        imageLoaded: true,
        imageNotFound: true,
      }));
    } else {
      const options: TResourceParams = { filename };
      if (original) {
        options.original = original;
      }
      GetResourceAPI(transactionCode, options, source.token).then((response) => {
        const { content, originalWidth, originalHeight } = response.data;
        setDocumentImage(content);
        setOriginalDimensions({ originalWidth, originalHeight });
        setResourceData((prevState) => ({ ...prevState, imageLoaded: true }));
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          setResourceData((prevState) => ({
            ...prevState,
            imageLoaded: true,
            imageNotFound: true,
          }));
        }
      });
    }
  }, [filename, specimenImage]);

  const imageSrc = useMemo(() => {
    if (resourceData.imageNotFound) {
      return getNotFoundImage(i18n.language, filetype);
    }
    if ('specimenImage' in props) {
      if (resourceData.imageNotFound) {
        return getNotFoundSpecimen(i18n.language);
      }
      if (resourceData.imageUnavailable) {
        return getServiceUnavailableImage(i18n.language);
      }
      return specimenImage;
    }
    return documentImage ? getImageFromBase64(documentImage) : '';
  }, [documentImage, resourceData, i18n.language]);

  return (<>
    <div className="wrapper-transform-component">
      <TransformWrapper
        zoomIn={{ step: 15 }}
        zoomOut={{ step: 15 }}
        defaultScale={1} scale={1}>
        {({
          zoomIn,
          zoomOut,
          wheel,
          setScale,
          setPositionX,
          setPositionY,
          setDefaultState,
          positionX,
          positionY,
          ...rest
        }: any) => {
          wheel.disabled = true;
          setupZoom(rest);
          const disabledZoomIn = (rest.scale === rest.options.maxScale) ? 'is-disabled' : '';
          const disabledZoomOut = (rest.scale === rest.options.minScale) ? 'is-disabled': '';
          return (
            <div className="transform-component">
              <div className="wrapper-image-container" ref={containerRef}>
                {!resourceData.imageLoaded && <div className="loader-container"><Loader /></div>}
                <TransformComponent>
                  <button
                    className='transform-image-button'
                    onClick={() => {
                      if (setFilename && documentImage) setFilename((filename) || '');
                    }}>
                    {
                      imageSrc && <img
                        src={imageSrc}
                        onLoad={() => onLoad(setPositionX, setPositionY, setDefaultState)}
                        ref={imageRef}
                        loading="lazy"
                        alt="transform-component"
                      />
                    }
                    {
                      coordinatesAreValid && (
                        <div
                          style={{
                            width: `${position.width}${EUnits.pixel}`,
                            height: `${position.height}${EUnits.pixel}`,
                            top: `${position.top}${EUnits.pixel}`,
                            left: `${position.left}${EUnits.pixel}`,
                            position: 'absolute',
                            zIndex: 1000,
                          }}
                          className="high-light-style"
                        />
                      )
                    }
                  </button>
                </TransformComponent>
              </div>
              {enableZoom && <div className="tools">
                <div
                  className={`zoom ${disabledZoomOut}`}
                  onClick={zoomOut}
                >
                  <ZoomOut />
                </div>
                <div
                  className={`zoom ${disabledZoomIn}`}
                  onClick={zoomIn}
                >
                  <ZoomIn />
                </div>
              </div>
              }
            </div>
          );
        }}
      </TransformWrapper>
    </div>
  </>);
};

export default ImageWrapper;