import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import { formatRejectionReasons, getMultipleFilenames } from 'helper';
import { IRootState } from 'store/types';
import { EAnswers, EFileTypes } from 'enums';
import Modal from 'components/modal/Modal';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import MultipleSelect from 'components/select/MultipleSelect';
import { A4ScanReviewRejectionReasons } from 'constants/Reasons';
import { IOptionType } from 'types';

const A4ScanReview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData, manualCheckResult } = state.reviewResult;
  const { rejectionReasons, a4ScanReview } = manualCheckResult;
  const [filename, setFilename] = useState('');
  const filenames = useMemo(() => getMultipleFilenames(transactionData, EFileTypes.a4Scan), [transactionCode]);
  const a4ScanReasons = useMemo(() => (
    formatRejectionReasons(A4ScanReviewRejectionReasons, rejectionReasons.a4ScanReview, t)
  ), [rejectionReasons.a4ScanReview]);

  const rejectionReasonsProps = {
    value: a4ScanReasons,
    handler: (selected: IOptionType[]) => dispatch(reviewResultActions.setRejectionReasons({
      ...rejectionReasons,
      a4ScanReview: selected?.map((reason: IOptionType) => reason.value) || []
    })),
    placeholder: t('a4.rejection-reason-placeholder'),
    options: A4ScanReviewRejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(reviewResultActions.setA4ScanReview({
      result: event.target.value,
    }));
    if (event.target.value === EAnswers.yes) {
      dispatch(reviewResultActions.setRejectionReasons({
        ...rejectionReasons,
        a4ScanReview: [],
      }));
    }
  }

  return (
    <div className='a4-scan-review-content'>
      <h1>{t('a4.heading')}</h1>
      <div className='a4-additional-scanned-images-content'>
        {
          filenames.map((filename: string, index: number) => (
            <div className='a4-document-item-content' key={index}>
              <ImageWrapper
                transactionCode={transactionCode}
                filetype={EFileTypes.a4Scan}
                filename={filename}
                setFilename={setFilename}
              />
            </div>
          ))
        }
      </div>
      <div className="line" />
      <div className="review-buttons">
        <h2 className="description">{t('review-footer.a4.question.title')}</h2>
        <div className='a4-reason-selection-section'>
          <div className='answer-section'>
            <Form.Check
              inline
              label={t('review-footer.answer-yes.title')}
              value={EAnswers.yes}
              name='a4.answer'
              className='radio-button'
              onChange={handleChange}
              type='radio'
              checked={a4ScanReview.result === EAnswers.yes}
            />
            <Form.Check
              inline
              label={t('review-footer.answer-no.title')}
              value={EAnswers.no}
              name='a4.answer'
              className='radio-button'
              onChange={handleChange}
              type='radio'
              checked={a4ScanReview.result === EAnswers.no}
            />
          </div>
          <div className='select-reasons'>
            <MultipleSelect
              disabled={a4ScanReview.result !== EAnswers.no}
              {...rejectionReasonsProps}
            />
          </div>
        </div>
      </div>
      {!!filename && <Modal
        show={!!filename}
        onHide={() => setFilename('')}
      >
        <div className='a4-image-wrapper-container'>
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={EFileTypes.a4Scan}
            filename={filename}
            enableZoom={true}
            original={true}
          />
        </div>
      </Modal>}
    </div>
  );
};

export default A4ScanReview;