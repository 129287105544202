import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import { IRootState } from 'store/types';
import { EFileTypes } from 'enums';
import { getFileNames } from 'helper';

interface IAddressImagesProps {
  setFilename: (filename: string) => void;
}

const AddressImages = (props: IAddressImagesProps) => {
  const { setFilename } = props;
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData } = state.reviewResult;

  const [
    firstImage = '', secondImage = '', addressCrop = '', addressDocument = '',
  ] = useMemo(() => getFileNames(transactionData, [
    EFileTypes.firstImage, EFileTypes.secondImage, EFileTypes.addressCrop, EFileTypes.addressDocument,
  ]), [transactionCode]);

  const firstImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.firstImage}
    filename={firstImage}
    setFilename={setFilename}
  />, []);

  const secondImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.secondImage}
    filename={secondImage}
    setFilename={setFilename}
  />, []);

  const addressCropImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.addressCrop}
    filename={addressCrop}
    setFilename={setFilename}
  />, []);

  const addressDocumentImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.addressDocument}
    filename={addressDocument}
    setFilename={setFilename}
    coordinates={transactionData?.addressCheck?.addressCoordinates}
  />, []);

  return (<>
    <div className='address-images-inner-content'>
      <div className='address-images-left-side'>
        <div className='address-document-image-style'>
          {addressDocumentImageWrapper}
        </div>
      </div>
      <div className='address-images-right-side'>
        <div className='address-crop-image-style'>
          {addressCropImageWrapper}
        </div>
        <div className='document-scan-image-style'>
          {firstImageWrapper}
        </div>
        <div className='document-scan-image-style'>
          {secondImageWrapper}
        </div>
      </div>
    </div>
  </>);
};

export default AddressImages;