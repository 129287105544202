import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EFileTypes } from 'enums';
import { getFileNames } from 'helper';
import { IRootState } from 'store/types';
import { TManualReview } from 'store/features/reviewResult/types';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import InputField from 'components/input/Input';
import Modal from 'components/modal/Modal';
import AccordionField from 'components/accordionField/AccordionField';

const DocumentValidation = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { reviewId = '' } = useParams();
  const [filename, setFilename] = useState('');
  const { transactionCode, transactionData } = state.reviewResult;
  const [
    fullFrameFirstPage = '', fullFrameSecondPage = '', signatureImage = '',
    firstImage = '', secondImage = '', firstVideo = '', secondVideo = '',
  ] = useMemo(() => getFileNames(transactionData, [
    EFileTypes.fullFrameFirstPage, EFileTypes.fullFrameSecondPage, EFileTypes.signatureImage,
    EFileTypes.firstImage, EFileTypes.secondImage, EFileTypes.firstVideo, EFileTypes.secondVideo,
  ]), [transactionCode]);
  const { manualReview = [] } = transactionData;
  const currentReview = useMemo(() => manualReview.find(
    (review: TManualReview) => review?.history?.reviewId === reviewId
  ), [manualReview, reviewId]);
  const { identityReview } = currentReview || {};
  const { documentValidationReview = [] } = identityReview || {};
  const { documentCountry, documentType, extraDetail } = documentValidationReview[0] || {};

  const fullFrameFirstPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameFirstPage}
    filename={fullFrameFirstPage}
    setFilename={setFilename}
  />, []);

  const fullFrameSecondPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameSecondPage}
    filename={fullFrameSecondPage}
    setFilename={setFilename}
  />, []);

  const firstPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.firstImage}
    filename={firstImage}
    setFilename={setFilename}
  />, []);

  const secondPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.secondImage}
    filename={secondImage}
    setFilename={setFilename}
  />, []);

  const signatureImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.signatureImage}
    filename={signatureImage}
    setFilename={setFilename}
  />, []);

  const firstVideoPlayer = useMemo(() => <VideoPlayer
    filename={firstVideo}
    filetype={EFileTypes.firstVideo}
    transactionCode={transactionCode}
  />, []);

  const secondVideoPlayer = useMemo(() => <VideoPlayer
    filename={secondVideo}
    filetype={EFileTypes.secondVideo}
    transactionCode={transactionCode}
  />, []);

  const content = <div className='document-validation'>
    <div className='clarifier-selection-section'>
      <InputField
        disabled={true}
        label={t('identity.doc.country.label')}
        placeholder={documentCountry || ''}
      />
      <InputField
        disabled={true}
        label={t('identity.doc.type.label')}
        placeholder={documentType || ''}
      />
      <InputField
        disabled={true}
        label={t('identity.doc.extra.label')}
        placeholder={extraDetail || ''}
      />
    </div>
    <div className='first-page-content'>
      <h2>{t('identity.doc.first-page.title')}</h2>
      <div className='image-wrapper-container'>
        {fullFrameFirstPageImageWrapper}
      </div>
      <div className='document-validation-image-comparison'>
        {firstPageImageWrapper}
      </div>
      <div className='video-player-section'>
        {firstVideoPlayer}
      </div>
    </div>
    <div className='second-page-content'>
      <h2>{t('identity.doc.second-page.title')}</h2>
      <div className='image-wrapper-container'>
        {fullFrameSecondPageImageWrapper}
      </div>
      <div className='document-validation-image-comparison'>
        {secondPageImageWrapper}
      </div>
      <div className='video-player-section'>
        {secondVideoPlayer}
      </div>
    </div>
    <div className='signature-content'>
      <h2>{t('identity.doc.signature.title')}</h2>
      <div className='image-wrapper-container'>
        {signatureImageWrapper}
      </div>
    </div>
    {!!filename && <Modal
      show={!!filename}
      onHide={() => setFilename('')}
    >
      <div className='face-image-wrapper-container'>
        <ImageWrapper
          transactionCode={transactionCode}
          filetype={EFileTypes.firstImage}
          filename={filename}
          enableZoom={true}
          original={true}
        />
      </div>
    </Modal>}
  </div>

  return (<>
    <div className='document-validation-content'>
      <AccordionField
        title={t('identity.doc.heading')}
        body={content}
        className={'accordion-container'}
        showStatus={!!currentReview?.identityReview}
        eventKey={1}
        preventToggle={!currentReview?.identityReview}
      />
    </div>
  </>);
};

export default DocumentValidation;
