import React from 'react';
import { useTable } from 'react-table';
import Loader from 'components/loader/Loader';
import { TFunction, useTranslation } from 'react-i18next';

import ToolTip from 'components/toolTipField/ToolTipField';

const DefaultError = (props: { t: TFunction }): React.JSX.Element => (
  <tr className="empty">
    <td className="empty-message">{props.t('dashboard.table.no-data')}</td>
  </tr>
);

const Table = (props: {
  data: any;
  columns: any;
  searchValue?: string;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  showSearch?: boolean;
  customHeader?: React.JSX.Element;
  customFooter?: React.JSX.Element;
  loading?: boolean;
  CustomErrorMessage?: React.ComponentType<{ t: TFunction }> | null;
}) => {
  const { t } = useTranslation();
  const {
    data, columns, searchValue, setSearchValue, showSearch,
    customHeader, customFooter, loading, CustomErrorMessage,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({
    columns,
    data,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue && setSearchValue(e.target.value || '');
  };

  const colSpan = columns[0]?.columns?.length || 1;

  const ErrorMessage = CustomErrorMessage ? <CustomErrorMessage t={t} /> : <DefaultError t={t} />;

  return (
    <table {...getTableProps()} className="ta-review-table">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index} >
            {headerGroup.headers.map((column, cIndex) => (
              <th {...column.getHeaderProps()} key={`${index}-${cIndex}`}>
                {headerGroup.headers.length === 1 ? (
                  <div className='table-heading'>
                    {customHeader || column.render('Header')}
                    {showSearch ?
                      <input
                        value={searchValue}
                        className="table-input"
                        onChange={handleChange}
                        placeholder={t('dashboard.search.filter.label')}
                      /> : ''
                    }
                  </div>
                ) : column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? (
          <tr className='table-loader'>
            <td colSpan={colSpan}>
              <div className='loading-container'>
                <Loader />
              </div>
            </td>
          </tr>
        ) : (
          data.length ? rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={data[i].isActive ? 'selected' : ''} key={i} >
                {row.cells.map((cell, cIndex) => (
                  <td
                    {...cell.getCellProps()}
                    className={cell.column.id === 'transactionState'
                      && typeof cell.value === 'string'
                      && cell.value.toLowerCase() === 'rejected' ?
                      'error' : cell.column.id === 'transactionState'
                        ? 'result' : ''
                    }
                    key={cIndex}
                  >
                    {['name', 'comments', 'orderId', 'accountName'].includes(cell.column.id) ? (
                      <ToolTip
                        tooltipValue={<p>{cell.value}</p>}
                        placement="top"
                      >
                        <p className="ellipsis">{cell.value}</p>
                      </ToolTip>
                    ) : cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          }) : ErrorMessage
        )}
      </tbody>
      {customFooter && <tfoot>
        {footerGroups.map((footerGroup, index) => (
          <tr {...footerGroup.getFooterGroupProps()} key={index} >
            {footerGroup.headers.map((column, cIndex) => (
              <td
                {...column.getFooterProps()}
                key={cIndex}
              >
                {footerGroup.headers.length === 1 && customFooter}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>}
    </table>
  );
};

export default Table;
