import { MutableRefObject } from 'react';
import { TFunction } from 'react-i18next';

import { EAddressDataFields, EAddressFields, EUnits } from 'enums';
import { IAddress, IPersonalDetails, ITAddressCoordinates, TAddressCheck } from 'store/features/reviewResult/types';
import { areValuesEqual, getCountryValue } from 'helper';
import { TGenericItem } from 'types';

type TOriginalDimensions = {
  originalWidth: number;
  originalHeight: number;
}

const convertCoordinatesToPixels = (coordinates: ITAddressCoordinates, image: {
  originalWidth: number;
  originalHeight: number;
}) => {
  switch (coordinates.unit) {
  case EUnits.percentage:
    return {
      x: (image.originalWidth * coordinates.x) / 100,
      y: (image.originalHeight * coordinates.y) / 100,
      width: (image.originalWidth * coordinates.width) / 100,
      height: (image.originalHeight * coordinates.height) / 100,
    }
  default:
    return coordinates;
  }
};

const calculateWhiteSpace = (clientWidth: number, clientHeight: number, imageAspect: number) => {
  if (clientWidth > 0 && clientHeight > 0) {
    const containerAspect = clientWidth / clientHeight;
    let scaledWidth, scaledHeight;
    if (imageAspect > containerAspect) {
      scaledWidth = clientWidth;
      scaledHeight = clientWidth / imageAspect;
    } else {
      scaledWidth = clientHeight * imageAspect;
      scaledHeight = clientHeight;
    }
    const top = clientHeight - scaledHeight;
    const left = clientWidth - scaledWidth;
    return { top, left };
  }
  return { top: 0, left: 0 };
};

const getRectanglePosition = (
  imageRef: MutableRefObject<HTMLImageElement>,
  containerRef: MutableRefObject<HTMLImageElement>,
  coordinates: ITAddressCoordinates | undefined,
  originalDimensions: TOriginalDimensions,
) => {
  const validNaturalDimensions = originalDimensions.originalHeight > 0 && originalDimensions.originalWidth > 0;
  if (coordinates && containerRef.current && imageRef.current && validNaturalDimensions) {
    const { clientWidth, clientHeight } = containerRef.current;
    const imageAspect = originalDimensions.originalWidth / originalDimensions.originalHeight;
    const whiteSpace = calculateWhiteSpace(clientWidth, clientHeight, imageAspect);
    const { offsetWidth, offsetHeight } = imageRef.current;
    const { x, y, width, height } = convertCoordinatesToPixels(coordinates, originalDimensions);
    const absoluteLeft = ((offsetWidth - whiteSpace.left) * x) / originalDimensions.originalWidth;
    const absoluteTop = ((offsetHeight - whiteSpace.top) * y) / originalDimensions.originalHeight;
    const absoluteWidth = ((offsetWidth - whiteSpace.left) * width) / originalDimensions.originalWidth;
    const absoluteHeight = ((offsetHeight - whiteSpace.top) * height) / originalDimensions.originalHeight;
    return {
      width: absoluteWidth,
      height: absoluteHeight,
      left: absoluteLeft + (whiteSpace.left / 2),
      top: absoluteTop + (whiteSpace.top / 2),
    };
  }
  return { width: 0, height: 0, left: 0, top: 0 };
};



type TSelfDeclarationItem = {
  field: string;
  value: string;
  equals: boolean;
  translationKey: string;
}

const getAddressValue = (address: IAddress | undefined, field: string) => {
  const addressField = EAddressFields[field as keyof typeof EAddressFields];
  return address?.[addressField] || '';
}

const getSelfDeclarationValue = (
  selfDeclarationData: IPersonalDetails | undefined,
  field: string,
) => {
  const fieldValue = selfDeclarationData?.[field as keyof IPersonalDetails];
  return typeof fieldValue === 'string' ? fieldValue : ''
};

const generateAddressCheckValue = (
  addressCheckData: TAddressCheck | undefined,
  field: string,
) => {
  const fieldValue = addressCheckData?.[field as keyof TAddressCheck];
  return typeof fieldValue === 'string' ? fieldValue : ''
};

const KEYS_MAPPING: TGenericItem = {
  [EAddressFields.houseNumber]: 'house-number',
  [EAddressFields.addressLine2]: 'line2',
  [EAddressFields.zipCode]: 'zipcode',
  [EAddressFields.countryCode]: 'country',
  [EAddressDataFields.firstName]: 'first-name',
  [EAddressDataFields.lastName]: 'last-name',
  [EAddressDataFields.maidenName]: 'maiden-name',
  [EAddressDataFields.phone]: 'phone-number',
};

const generateData = (
  selfDeclarationData: IPersonalDetails | undefined,
  addressCheckData: TAddressCheck | undefined,
  fields: EAddressDataFields[],
  sdField: boolean,
) => (
  fields.reduce((acc: TSelfDeclarationItem[], field) => {
    if (field === EAddressDataFields.address) {
      Object.keys(EAddressFields).forEach((aField) => {
        const value = getAddressValue(sdField ? selfDeclarationData?.address : addressCheckData?.address, aField);
        const targetValue = getAddressValue(sdField ? addressCheckData?.address : selfDeclarationData?.address, aField);
        const translationKey = `address.${KEYS_MAPPING[aField] || aField}.title`;
        acc.push({
          field: aField,
          value,
          equals: (value && targetValue) ? areValuesEqual(value, targetValue) : true,
          translationKey
        });
      });
    } else {
      const value = sdField ?
        getSelfDeclarationValue(selfDeclarationData, field) : generateAddressCheckValue(addressCheckData, field);
      const targetValue = sdField ?
        generateAddressCheckValue(addressCheckData, field) : getSelfDeclarationValue(selfDeclarationData, field);
      const translationKey = `address.${KEYS_MAPPING[field] || field}.title`;
      acc.push({
        field,
        value,
        equals: (value && targetValue) ? areValuesEqual(value, targetValue) : true,
        translationKey,
      });
    }
    return acc;
  }, [])
);

const getModifiedValue = (item: TSelfDeclarationItem, t: TFunction) => {
  if (([EAddressFields.countryCode, EAddressDataFields.nationality] as string[]).includes(item.field)) {
    return getCountryValue(item.value, t)?.label || item.value;
  }
  return item.value;
}

export {
  getRectanglePosition,
  getModifiedValue,
  generateData,
};
