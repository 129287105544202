import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import { ReactComponent as DownIcon } from 'images/arrowDown.svg';
import { ReactComponent as UpIcon } from 'images/arrowUp.svg';
import { useTranslation } from 'react-i18next';

interface IAccordionFieldProps {
  title: string | any;
  body: any;
  className: string;
  showStatus: boolean;
  eventKey: number;
  setStatusByEventKey?: (eventKey: number) => void;
  preventToggle?: boolean;
}

interface IContextAwareToggleProps {
  children: any;
  isOpensFields: boolean;
  preventToggle: boolean;
}

const ContextAwareToggle =
  ({ children, isOpensFields, preventToggle }: IContextAwareToggleProps) => {
    return (
      <div className={'accordion-title'}>
        <div className={`left-info ${preventToggle ? 'disabled' : ''}`}>
          {isOpensFields ? <UpIcon className="up-icon" /> : <DownIcon className="up-icon" />}
          <p className="accordion-title-info">{children}</p>
        </div>
      </div>
    );
  };

const AccordionField = (props: IAccordionFieldProps) => {
  const { title, body, className, showStatus, eventKey, setStatusByEventKey, preventToggle = false } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(showStatus);
  }, [showStatus]);

  return (
    <div className={`default-acordion-style ${className}`}>
      <Accordion key={eventKey}>
        <Card>
          <Card.Header
            onClick={() => {
              if (preventToggle) return; // Prevent toggling
              setIsOpen(!isOpen);
              setStatusByEventKey && setStatusByEventKey(eventKey);
            }}
            onKeyPress={() => {
              if (preventToggle) return; // Prevent toggling
              setIsOpen(!isOpen);
            }}
            tabIndex={eventKey}
            key={eventKey}
          >
            <ContextAwareToggle
              isOpensFields={isOpen}
              preventToggle={preventToggle}
            >
              {t(title)}
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={String(eventKey)}
            in={isOpen}
          >
            <Card.Body>{body}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default AccordionField;