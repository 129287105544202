import moment from 'moment';

import { EFields, EValidationTypes, EZones } from 'enums';
import { DATE_FORMAT } from 'constants/Static';
import { REGULAR_FIELDS } from 'constants/Fields';

export const FIELD_VALIDATION_TYPES = {
  STRING: 'string',
  STRING_AND_NUMBER: 'string_and_number',
  ONLY_STRING: 'only_string',
  DATE: 'date',
  GENDER: 'gender',
};

export const dateIsValid = (date: string) => moment(date, DATE_FORMAT, true).isValid();

const validatedByRegex = (type: EValidationTypes | undefined, value: string, zone: EZones) => {
  switch (type) {
  case EValidationTypes.string:
    if (zone === EZones.mrz) {
      return /^[0-9a-zA-Z]*$/.test(value);
    }
    // eslint-disable-next-line max-len, no-case-declarations
    return /^[ !%'()*+,-./0123456789:;<>ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿĀāĂăĄąĆćĈĉċČčĎďĐđĒēĖėĘęĚěĜĝĞğĠġĢģĤĥĩĪīĭĮįİıĴĵĻĽľŁłŃńŅņŇňŌōŏŐőŒœŘřŚśŜŝŞşŠšŢţŤťŨũŪūŬŭŮůŰűųŵŸŹźŻżŽžơǍǎǐǔȘșȚțḤḨḩ•\\]*$/g.test(value);
  case EValidationTypes.date:
    return dateIsValid(value);
  case EValidationTypes.stringAndNumber:
    if (zone === EZones.mrz) {
      return /^[0-9a-zA-Z ]*$/.test(value);
    }
    return /(^[0-9a-zA-Z]{0,1}$)|(^[0-9a-zA-Z][0-9a-zA-Z-.]*([0-9a-zA-Z]{1,}$))/g.test(value);
  case EValidationTypes.onlyString:
    if (zone === EZones.mrz) {
      return /^[a-zA-Z ]*$/.test(value);
    }
    // eslint-disable-next-line max-len, no-case-declarations
    return /^[ !%'()*+,-./0123456789:;<>ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿĀāĂăĄąĆćĈĉċČčĎďĐđĒēĖėĘęĚěĜĝĞğĠġĢģĤĥĩĪīĭĮįİıĴĵĻĽľŁłŃńŅņŇňŌōŏŐőŒœŘřŚśŜŝŞşŠšŢţŤťŨũŪūŬŭŮůŰűųŵŸŹźŻżŽžơǍǎǐǔȘșȚțḤḨḩ•\\]*$/g.test(value);
  case EValidationTypes.gender:
    return /^m$|^f$|^-$/.test(value);
  default:
    return true;
  }
};

const fieldValueValidated = (
  field: EFields,
  zone: EZones,
  value: string,
  optional: boolean,
): boolean => {
  if (!value && optional) return true;
  const { maxLength = 0, validationType } = REGULAR_FIELDS[field] || {};
  const sizeAllowed = value.length <= maxLength;
  return sizeAllowed && validatedByRegex(validationType, value, zone);
};

const isValidEmail = (email = '') => !!(email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));

const getPasswordRequirements = (password: string) => {
  return [
    {
      // Minimum length: 12
      message: 'set-password.message.option-1',
      valid: password && password.length >= 12,
    },
    {
      // Maximum length: 100
      message: 'set-password.message.option-2',
      valid: password && password.length <= 100,
    },
    {
      // Upper case: min 1
      message: 'set-password.message.option-3',
      valid: password && /[A-Z]/.test(password),
    },
    {
      // Lower case: min 1
      message: 'set-password.message.option-4',
      valid: password && /[a-z]/.test(password),
    },
    {
      // Digits: min 2
      message: 'set-password.message.option-5',
      valid: password && /(\D*\d\D*){2}/.test(password),
    },
    {
      // No spaces allowed
      message: 'set-password.message.option-6',
      valid: password && /^\S*$/.test(password),
    },
  ];
}

export {
  isValidEmail,
  fieldValueValidated,
  getPasswordRequirements
}