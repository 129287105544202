import React from 'react';
import { useSelector } from 'react-redux';

import InfoBar from 'pages/review/infoBar/InfoBar';
import TransactionSummary from 'pages/summary/transactionSummary/TransactionSummary';
import { IRootState } from 'store/types';
import { TManualReview } from 'store/features/reviewResult/types';
import DataVerification from 'pages/summary/dataVerification/DataVerification';
import DocumentValidation from 'pages/summary/documentValidation/DocumentValidation';
import SelfieValidation from 'pages/summary/selfieVideo/SelfieVideo';
import FaceVerification from 'pages/summary/faceVerification/FaceVerification';
import ScannedDocument from 'pages/summary/scannedDocument/ScannedDocument';
import AddressReview from 'pages/summary/addressReview/AddressReview';

const Summary = () => {
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const { manualReview } = transactionData;
  const reviewEndTimes = manualReview?.map((item: TManualReview) => item?.history?.endReviewAt || 0);

  return (<>
    <InfoBar unlockTransaction={false} reviewEndTimes={reviewEndTimes} />
    <div className='summery-review-content'>
      <div className='transaction-summary-section'>
        <TransactionSummary />
      </div>
      <div className='dv-summary-section'>
        <DocumentValidation />
        <DataVerification />
      </div>
      <div className='selfie-and-doc-scan-summary-section'>
        <div className='selfie-validation-section'>
          <SelfieValidation />
          <FaceVerification />
        </div>
        <div className='doc-scan-summary-section'>
          <ScannedDocument />
        </div>
      </div>
      <div className='address-review-section'>
        <AddressReview />
      </div>
    </div>
  </>);
};

export default Summary;
