import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getFileNames } from 'helper';
import { EFileTypes, EStatuses, EWorkflowModules } from 'enums';
import { IRootState } from 'store/types';
import { TManualReview } from 'store/features/reviewResult/types';
import { fvStatusMessages } from 'constants/Static';
import Modal from 'components/modal/Modal';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import AccordionField from 'components/accordionField/AccordionField';

const FaceVerificationContainer = () => {
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { reviewId = '' } = useParams();
  const [filename, setFilename] = useState('');
  const { transactionCode, transactionData } = state.reviewResult;
  const pageEnabled = transactionData?.modules.includes(EWorkflowModules.faceVerification);
  const { manualReview = [] } = transactionData;
  const currentReview = useMemo(() => manualReview.find(
    (review: TManualReview) => review?.history?.reviewId === reviewId
  ), [manualReview, reviewId]);
  const { identityReview } = currentReview || {};
  const { faceVerificationReview } = identityReview || {};
  const result = EStatuses[faceVerificationReview?.result as keyof typeof EStatuses] || EStatuses.notPerformed;
  const [faceAvatar = '', faceImage = ''] = useMemo(() =>
    getFileNames(transactionData, [EFileTypes.faceAvatar, EFileTypes.faceImage]),
  [transactionCode]);

  const faceAvatarImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.faceAvatar}
    filename={faceAvatar}
    setFilename={setFilename}
  />, []);

  const faceImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.faceImage}
    filename={faceImage}
    setFilename={setFilename}
  />, []);

  const content = useMemo(() => {
    return (
      <div className="face-verification-section">
        <p className="face-verification-status">
          {t('identity.fv.result.title')}
          <span className={result}>{pageEnabled && t(fvStatusMessages[result])}</span>
        </p>
        <div className='face-verification-images'>
          {faceImageWrapper}
          {faceAvatarImageWrapper}
        </div>
      </div>
    );
  }, [transactionCode, transactionData]);

  return (<>
    <div className='face-verification-content'>
      <AccordionField
        title={t('identity.fv.heading')}
        body={content}
        className={'accordion-container'}
        showStatus={pageEnabled}
        eventKey={1}
        preventToggle={!pageEnabled}
      />
      {!!filename && <Modal
        show={!!filename}
        onHide={() => setFilename('')}
      >
        <div className='face-image-wrapper-container'>
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={EFileTypes.faceImage}
            filename={filename}
            enableZoom={true}
            original={true}
          />
        </div>
      </Modal>}
    </div>
  </>);
};

export default FaceVerificationContainer;
