import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { batchActions } from 'redux-batched-actions';

import {
  GetTransactionDataAPI,
  GetTransactionsAPI,
  LockTransactionAPI,
  LogErrorAPI,
  UnlockTransactionAPI,
} from 'helper/api/route';
import SwitchDatasetsModal from 'pages/dashboard/Modals/SwitchDatasets';
import SearchTransactionModal from 'pages/dashboard/Modals/SearchTransaction';
import ErrorMessagesModal from 'pages/dashboard/Modals/ErrorMessages';
import LockTransaction from 'pages/dashboard/LockTransaction';
import * as commonActions from 'store/features/common/actions';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { IRootState } from 'store/types';
import {
  IStyledText,
  TGetTransactionOptions,
  TLockTransaction,
  TReviewedTransaction,
  TTransactionRecord,
} from 'types';
import { convertTimestampToDate, getErrorMessage, getExtractedData, getSelectedDataset, toTitleCase } from 'helper';
import TransactionsTable from 'pages/dashboard/Table/Transactions';
import { ERoutePaths, ESearchType } from 'enums';
import { DEFAULT_DASHBOARD_ITEM } from 'constants/Static';
import { TManualReview } from 'store/features/reviewResult/types';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state.common?.dashboard || DEFAULT_DASHBOARD_ITEM;
  const { datasets = [] } = state?.agentProfile?.agent || {};
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSearchModalShowing, setSearchModalIsShowing] = useState(false);
  const [isSwitchModalShowing, setSwitchModalIsShowing] = useState(false);
  const [isErrorModalShowing, setErrorModalIsShowing] = useState(false);
  const [searchType, setSearchType] = useState(ESearchType.transactionCode);
  const [message, setMessage] = useState<IStyledText[]>([]);
  const defaultTransactionCode = state.common?.defaultTransactionCode || '';
  const dataset = useMemo(() => getSelectedDataset(datasets), [datasets]);
  const invalidDataset = !dataset?.id || !dataset.accounts.length;

  useEffect(() => {
    if (defaultTransactionCode) {
      startReview({ transactionCode: defaultTransactionCode, orderId: '' });
    }
  } , [defaultTransactionCode]);

  const startReview = ({ transactionCode, orderId }: TLockTransaction) => {
    if (invalidDataset) return;
    setIsWaiting(true);
    dispatch(commonActions.setDefaultTransactionCode(''));
    LockTransactionAPI({ transactionCode, orderId }).then((response) => {
      const { transactionCode } = response.data;
      GetTransactionDataAPI(transactionCode).then((response) => {
        const {
          documentCountry, documentType, extraDetail, classifiedDocument
        } = getExtractedData(response.data.documentVerification);
        batchActions([
          dispatch(reviewResultActions.setTransactionData({
            transactionData: response.data,
            transactionCode,
          })),
          dispatch(reviewResultActions.setDocumentValidation({
            documentCountry,
            documentType,
            extraDetail,
            classifiedDocument,
          })),
        ])
        setIsWaiting(false);
        navigate(`${ERoutePaths.taBoarding}/${transactionCode}`);
      }).catch(() => {
        setIsWaiting(false);
        setMessage([
          { text: t('messages.inError') },
          { text: transactionCode, type: 'bold' },
          { text: t('messages.notAvailable') },
        ]);
        setErrorModalIsShowing(true);
        UnlockTransactionAPI(transactionCode);
      });
    }).catch((error) => {
      const state = error?.response?.data?.message || '';
      const errorMessage = getErrorMessage(state, transactionCode || '');
      setMessage(errorMessage);
      setErrorModalIsShowing(true);
      setIsWaiting(false);
    });
  };

  const openSummary = (transaction: TReviewedTransaction) => {
    const { transactionCode } = transaction;
    setIsWaiting(true);
    GetTransactionDataAPI(transactionCode).then((response) => {
      const manualReview = response.data.manualReview;
      if (!manualReview || manualReview.length !== transaction.history.length) {
        setIsWaiting(false);
        setMessage([
          { text: t('messages.inError') },
          { text: transactionCode, type: 'bold' },
          { text: t('messages.invalid-for-summary') },
        ]);
        setErrorModalIsShowing(true);
        return;
      }
      const combinedManualReview = manualReview.reduce((acc: TManualReview[], item, index) => {
        const transactionHistory = transaction.history.find((h) => (
          h.reviewId && item.reviewId && h.reviewId === item.reviewId
        ));
        if (transactionHistory) {
          acc.push({ ...item, history: transactionHistory });
        } else {
          const transactionHistory = transaction.history[index];
          const reviewId = `${transactionCode}${transactionHistory.endReviewAt}`;
          acc.push({
            ...item,
            reviewId,
            history: { ...transactionHistory, reviewId },
          });
        }
        return acc;
      }, [])
        .sort((startDate: TManualReview, endDate: TManualReview) => {
          return new Date(endDate.history?.endReviewAt || 0).getTime()
            - new Date(startDate.history?.startReviewAt || 0).getTime();
        });
      dispatch(reviewResultActions.setTransactionData({
        transactionData: { ...response.data, manualReview: combinedManualReview },
        transactionCode,
      }));
      const [{ reviewId }] = combinedManualReview;
      setIsWaiting(false);
      navigate(`${ERoutePaths.summary}/${transaction.transactionCode}/${reviewId}`);
    }).catch((error) => {
      setIsWaiting(false);
      setMessage([
        { text: t('messages.inError') },
        { text: transactionCode, type: 'bold' },
        { text: t('messages.invalid-for-summary') },
      ]);
      setErrorModalIsShowing(true);
    });
  };

  const getTransactions = (options?: TGetTransactionOptions) => {
    if (invalidDataset) {
      dispatch(commonActions.setDashboardData({ ...dashboard, items: [] }));
      return;
    }
    const page = options?.specificPage || dashboard?.page || 1;
    const limit = state.common?.appConfig?.dashboardTableRowCount || 20;
    const active = options?.active !== undefined ? options.active : dashboard?.active;
    dispatch(commonActions.setLoading(true));
    const offset = page === 1 ? 0 : (page - 1) * limit;
    GetTransactionsAPI({ limit, offset, reviewed: !active, datasetId: dataset.id }).then((response) => {
      const { items = [], totalItems } = response.data;
      const formattedItems: TTransactionRecord[] = items?.reduce((acc: TTransactionRecord[], item) => {
        const transactionRecord: TTransactionRecord = {
          transactionCode: item.transactionCode,
          accountName: item.accountName,
          orderId: item.orderId,
          transactionState: toTitleCase(item.transactionState),
          completedTime: 'completedTime' in item ? convertTimestampToDate(item.completedTime) : '',
        };
        if ('history' in item) {
          const {
            completedTime, reviewState, endReviewAt, reviewedBy, comments,
          } = item.history[item.history.length - 1];
          transactionRecord.transactionState = toTitleCase(reviewState);
          transactionRecord.completedTime = convertTimestampToDate(completedTime);
          transactionRecord.endReviewAt = convertTimestampToDate(endReviewAt);
          transactionRecord.reviewedBy = reviewedBy;
          transactionRecord.comments = comments;
          transactionRecord.history = item.history;
        }
        acc.push(transactionRecord);
        return acc;
      }, []) || [];
      dispatch(commonActions.setDashboardData({ active, page, items: formattedItems, totalItems, size: limit }));
    }).catch((error) => {
      LogErrorAPI(error);
      if (dashboard) {
        dispatch(commonActions.setDashboardData({ ...dashboard, items: [] }));
      }
    }).finally(() => dispatch(commonActions.setLoading(false)));
  };

  const modalIsShowing = isSearchModalShowing || isSwitchModalShowing || isErrorModalShowing;

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <div
          className={`overlap-area ${!modalIsShowing ? 'overlap_disable' : ''}`}
        />
        {isSearchModalShowing && (
          <SearchTransactionModal
            close={setSearchModalIsShowing}
            startReview={startReview}
            searchType={searchType}
          />
        )}
        {isSwitchModalShowing && (
          <SwitchDatasetsModal close={setSwitchModalIsShowing} />
        )}
        {isErrorModalShowing && (
          <ErrorMessagesModal
            close={setErrorModalIsShowing}
            message={message}
          />
        )}
        <LockTransaction
          setSearchModalIsShowing={setSearchModalIsShowing}
          setSearchType={setSearchType}
          setSwitchModalIsShowing={setSwitchModalIsShowing}
          isWaiting={isWaiting}
          onRefresh={getTransactions}
          startReview={startReview}
        />
        <TransactionsTable
          getTransactions={getTransactions}
          startReview={startReview}
          openSummary={openSummary}
          reviewStarted={isWaiting}
        />
      </div>
    </div>
  );
};

export default Dashboard;
