import React, { Fragment, useState } from 'react';

import FilesContent from 'components/accordionMenu/content/Authoritative';
import Knowledge from 'components/accordionMenu/content/Knowledge';
import AccordionField from 'components/accordionField/AccordionField';
import { useTranslation } from 'react-i18next';

const AccordionMenu = () => {
  const { t } = useTranslation();
  const [statusListAccordionFields, setStatusListAccordionFields]: any = useState([]);
  const accordionProps = [];
  accordionProps.push(<AccordionField
    title={t('review-header.help.header1')}
    body={<FilesContent />}
    className="left-menu-container-accordion-field"
    showStatus={false}
    eventKey={1}
    setStatusByEventKey={(eventKey) => handleAccordionFieldsStatus(eventKey)}
  />);
  accordionProps.push(<AccordionField
    title={t('review-header.help.header2')}
    body={<Knowledge />}
    className="left-menu-container-accordion-field"
    showStatus={false}
    eventKey={1}
    setStatusByEventKey={(eventKey) => handleAccordionFieldsStatus(eventKey)}
  />);

  const handleAccordionFieldsStatus = (eventKey: number) => {
    const newStatusList: any = [...statusListAccordionFields];
    if (!newStatusList?.includes(eventKey)) {
      newStatusList.push(eventKey);
      setStatusListAccordionFields(newStatusList);
    } else {
      newStatusList.splice(newStatusList.indexOf(eventKey), 1);
      setStatusListAccordionFields(newStatusList);
    }
  };

  return (
    <>
      <div className='left-menu-accordions-container'>
        {accordionProps?.map((child: React.ReactNode, index: number) =>
          <Fragment key={index} >
            {child}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default AccordionMenu;
