import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { ERoutePaths } from 'enums';
import { isAuthorized, logOut } from 'util/authService';
import { IRootState } from 'store/types';
import { GetAgentAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import * as agentActions from 'store/features/agentManagement/actions';
import * as commonActions from 'store/features/common/actions';
import { TDataset } from 'store/features/agentManagement/types';

const ConditionalRedirect = () => {
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { agent, datasetId } = state.agentProfile || {};
  const isUserAuthorizedWithoutAgent = isAuthorized() && !agent;

  useEffect(() => {
    if (isUserAuthorizedWithoutAgent) {
      dispatch(commonActions.setLoading(true));
      GetAgentAPI().then((response) => {
        const datasets = response.data.datasets.reduce((acc: TDataset[], dataset) => {
          acc.push({ ...dataset, selected: dataset.id === datasetId });
          return acc;
        }, []);
        dispatch(batchActions([
          agentActions.setAgent({ ...response.data, datasets }),
          commonActions.setLoading(false),
        ]));
      }).catch(() => {
        dispatch(commonActions.setLoading(false));
        logOut();
      });
    }
  }, [agent]);

  if (state.common?.loading || isUserAuthorizedWithoutAgent) {
    return <div className="loader-container"> <Loader /></div>;
  }

  if (!isAuthorized()) {
    return <Navigate to={ERoutePaths.signIn} />;
  }
  return <Navigate to={ERoutePaths.dashboard} />;
};

export default ConditionalRedirect;
